import React, { useEffect, useState } from "react"
import {
  Badge, Button, Card, CardBody, CardTitle,
  Col,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import companies from "../../assets/images/companies"
import { Link } from "react-router-dom"
import images from "../../assets/images"
import { courseDelete, courseGet, courseGet as onGet, coursePublish, courseSubmit } from "../../store/actions"
import axios from "axios"
import { CourseStatus } from "../../components/app/statusBudges"
import CourseFormCanvas from "./form/courseFormCanvas"
import { CourseList } from "./components/courseList"

const AllCourses = () => {

  const [form, setForm] = useState(false)
  const [courseId, setCourseId] = useState(0)


  //meta title
  document.title = "All Courses"

  const dispatch = useDispatch()
  const { Courses } = useSelector(state => state)

  const { courses, loading } = Courses
  // const [courses, setCourses] = useState([])

  // const get = () => axios.get('app/course/get')
  //   .then(({data}) => setCourses(data))
  //   .catch(({message}) => console.log(message))

  useEffect(() => {
    console.log("Get Courses")
    // get()
    dispatch(onGet())


  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Courses" breadcrumbItem="All Courses" />
          <Row>
            <Col lg="12">
              <div>
                <Card className="bg-transparent">
                  <CardBody className="bg-white">
                    <Button onClick={() => {
                      setForm(true)
                      setCourseId(0)
                    }} outline color="primary" className="float-end"><i className="bx bx-plus-circle"></i> Create Course</Button>
                    <CardTitle>Courses</CardTitle>
                  </CardBody>
                  <CourseList loading={loading} courses={courses} />
                </Card>


              </div>
            </Col>
          </Row>



        </Container>
      </div>

      <CourseFormCanvas courseId={courseId} isOpen={form} callBack={(fields) => {
        setForm(false)
        dispatch(courseSubmit(fields))
        dispatch(courseGet())
      }} toggle={() => setForm(!form)} categoryId={0} />
    </React.Fragment>
  )
}

export default AllCourses
