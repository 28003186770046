import React, {useEffect, useState} from 'react';
import UploadComponent from './upload';
import ScreenShot from './ScreenShot';
import {Button} from 'reactstrap';
import VideoSnapshot from 'video-snapshot';
import axios from 'axios';
import {Pre} from '../dev/pre';


export const UploadVideo = ({title, video, updateVideo, height, label}) => {
    const [cVideo, setCVideo] = useState(video);
    const [isOpen, setIsOpen] = useState(false);
    const [blob, setBlob] = useState(false);
    const [screenShot, setScreenShot] = useState(false);
    useEffect(() => {
        cVideo && setIsOpen(true);


    }, [cVideo]);
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    };


    return <React.Fragment>
        <UploadComponent

            setBlob={setBlob}
            label={label}
            height={height}
            video
            title={title ? title : 'Upload Video'}
            onStart={() => updateVideo(false)}
            filename="video"
            onUpload={data => {
                updateVideo(data);
                setCVideo(data);
            }}
        />

        {cVideo && <img src={cVideo.thumbnail} className="img-fluid"/>}


    </React.Fragment>;
};
