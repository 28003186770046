

export const COURSE_GET_REQUEST = 'COURSE_GET_REQUEST';
export const COURSE_GET_SUCCESS = 'COURSE_GET_SUCCESS';
export const COURSE_GET_FAILURE = 'COURSE_GET_FAILURE';

export const COURSE_FIND_REQUEST = 'COURSE_FIND_REQUEST';
export const COURSE_FIND_SUCCESS = 'COURSE_FIND_SUCCESS';
export const COURSE_FIND_FAILURE = 'COURSE_FIND_FAILURE';

export const COURSE_FORM_REQUEST = 'COURSE_FORM_REQUEST';
export const COURSE_FORM_SUCCESS = 'COURSE_FORM_SUCCESS';
export const COURSE_FORM_FAILURE = 'COURSE_FORM_FAILURE';

export const COURSE_SUBMIT_REQUEST = 'COURSE_SUBMIT_REQUEST';
export const COURSE_SUBMIT_SUCCESS = 'COURSE_SUBMIT_SUCCESS';
export const COURSE_SUBMIT_FAILURE = 'COURSE_SUBMIT_FAILURE';

export const COURSE_PUBLISH_REQUEST = 'COURSE_PUBLISH_REQUEST';
export const COURSE_PUBLISH_SUCCESS = 'COURSE_PUBLISH_SUCCESS';
export const COURSE_PUBLISH_FAILURE = 'COURSE_PUBLISH_FAILURE';

export const COURSE_DELETE_REQUEST = 'COURSE_DELETE_REQUEST';
export const COURSE_DELETE_SUCCESS = 'COURSE_DELETE_SUCCESS';
export const COURSE_DELETE_FAILURE = 'COURSE_DELETE_FAILURE';

export const COURSE_SUSPEND_REQUEST = 'COURSE_SUSPEND_REQUEST';
export const COURSE_SUSPEND_SUCCESS = 'COURSE_SUSPEND_SUCCESS';
export const COURSE_SUSPEND_FAILURE = 'COURSE_SUSPEND_FAILURE';


