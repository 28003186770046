export const BIN_GET_REQUEST = 'BIN_GET_REQUEST';
export const BIN_GET_SUCCESS = 'BIN_GET_SUCCESS';
export const BIN_GET_FAILURE = 'BIN_GET_FAILURE';

export const BIN_FIND_REQUEST = 'BIN_FIND_REQUEST';
export const BIN_FIND_SUCCESS = 'BIN_FIND_SUCCESS';
export const BIN_FIND_FAILURE = 'BIN_FIND_FAILURE';

export const BIN_DELETE_REQUEST = 'BIN_DELETE_REQUEST';
export const BIN_DELETE_SUCCESS = 'BIN_DELETE_SUCCESS';
export const BIN_DELETE_FAILURE = 'BIN_DELETE_FAILURE';

export const BIN_RESTORE_REQUEST = 'BIN_RESTORE_REQUEST';
export const BIN_RESTORE_SUCCESS = 'BIN_RESTORE_SUCCESS';
export const BIN_RESTORE_FAILURE = 'BIN_DELETE_FAILURE';

