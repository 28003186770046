import {ADMIN_MPESA_GET_REQUEST,
  ADMIN_MPESA_GET_SUCCESS,
  ADMIN_MPESA_GET_FAILURE,
  ADMIN_MPESA_FIND_REQUEST,
  ADMIN_MPESA_FIND_SUCCESS,
  ADMIN_MPESA_FIND_FAILURE,
  ADMIN_MPESA_ACTION_REQUEST,
  ADMIN_MPESA_ACTION_SUCCESS,
  ADMIN_MPESA_ACTION_FAILURE} from './adminMpesaTypes';

const INIT = {
  records: [],
  record: false,
  loading: false,
  error: ''
}

const MPesa = (state = INIT, action) => {

  switch (action.type){
    case ADMIN_MPESA_GET_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_MPESA_GET_SUCCESS: return {...state, loading: false, error: '', records: action.payload}
    case ADMIN_MPESA_GET_FAILURE: return {...state, loading: false, error: action.payload}

    case ADMIN_MPESA_FIND_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_MPESA_FIND_SUCCESS: return {...state, loading: false, error: '', record: action.payload}
    case ADMIN_MPESA_FIND_FAILURE: return {...state, loading: false, error: action.payload}

    case ADMIN_MPESA_ACTION_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_MPESA_ACTION_SUCCESS: return {...state, loading: false, error: ''}
    case ADMIN_MPESA_ACTION_FAILURE: return {...state, loading: false, error: action.payload}

    default : return  {...state}
  }


}


export default MPesa
