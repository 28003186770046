import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink, Spinner
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { adminLessonGet } from "../../store/admin/lessons/adminLessonActions"
import { LessonsList } from "./components/lessonsList"
import classnames from "classnames"
import { CourseDetailsModal } from "../courses/courseDetailsModal"
import { LessonDetailModal } from "./lessonDetailModal"

const AdminLessons = () => {
  const { AdminLessons } = useSelector(state => state)
  const dispatch = useDispatch()
  const { lessons, loading } = AdminLessons
  const [active, setActive] = useState("pending")
  const [omits, setOmits] = useState([])
  const [search, setSearch] = useState("")
  const [lesson, setLesson] = useState(false)
  const [modal, setModal] = useState(false)
  const get = () => dispatch(adminLessonGet())

  useEffect(get, [])
  useEffect(() => setModal(lesson !== false), [lesson, modal])


  //meta title
  document.title = "App Skill | Admin | Lessons"
  useEffect(() => {
    if (active === "pending") setOmits(["approval", "traffic"])
    if (active === "rejected") setOmits(["approval", "traffic", "actions"])
    if (active === "approved") setOmits(["actions", "approval"])

  }, [active])
  const colors = [
    { approval: "pending", color: "warning", icon: "bx bx-hourglass" },
    { approval: "rejected", color: "danger", icon: "bx bx-block" },
    { approval: "approved", color: "success", icon: "bx bx-check-circle" }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Courses" breadcrumbItem="Lessons" />

          <Card className="bg-transparent">

            <CardBody className="bg-white">
              <FormGroup className="input-group inline float-end">
                <Input placeholder="Search Lessons..." value={search} onChange={e => setSearch(e.target.value)} />
                <Button className="" size="sm" outline color={"primary"} onClick={get}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <i className="mbi mdi mdi-reload"></i>}
                  {" "} Reload
                </Button>
              </FormGroup>
            </CardBody>

            <Nav tabs className="nav-tabs-custom nav-justified">
              {colors.map((row, index) => <NavItem className="rounded-0" key={`approval-${index}`}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={`${classnames({ active: active === row.approval })} rounded-0`}
                  onClick={() => setActive(row.approval)}
                >
                  <i className={`${row.icon} text-${row.color} font-size-20`}></i>
                  <span className="d-none d-sm-block ">
                          {row.approval.toUpperCase()}{" "}
                    <Badge className="me-1" color={row.color}>
                        {" "} {lessons.filter(course => course.approval === row.approval)
                      .filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase())).length}
                      </Badge>
                  </span>
                </NavLink>
              </NavItem>)}
            </Nav>
            <LessonsList onClick={lessonID => setLesson(lessonID)} lessons={lessons.filter(row => row.approval === active)
              .filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()))} omits={omits}
                         loading={loading} />
          </Card>

        </Container>
      </div>
      <LessonDetailModal toggle={() => setLesson(false)} isOpen={modal} lessonID={lesson} />

    </React.Fragment>
  )
}

export default AdminLessons
