import React, { useState } from "react"
import { Card, CardBody, Col, Form, Input, Label, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"


export const WizardComponent = ({ tabs, title, handleSubmit }) => {

  const [activeTab, setActiveTab] = useState(0)
  const toggleTab = tab => setActiveTab(tab)
  return <Card>
    <CardBody>
      <h4 className="card-title mb-4">{title ? title : "Create"}</h4>
      <div className="wizard clearfix">
        <div className="steps clearfix">
          <ul>
            {tabs.map((row, index) => <NavItem
                key={`nav-item-wizard-${index}`}
                className={classnames({ current: activeTab === index })}
              >
                <NavLink
                  className={classnames({ current: activeTab === index })}
                  onClick={() => {
                    setActiveTab(index)
                  }}
                  // disabled={!(passedSteps || []).includes(index)}
                >
                  <span className="number">{index + 1}.</span> {row.title}
                </NavLink>
              </NavItem>
            )}

          </ul>
        </div>
        <div className="content clearfix">
          <TabContent activeTab={activeTab} className="body">
            {tabs.map((tab, index) => <TabPane key={`tab-content-${index}`} tabId={index}>
              {tab.component}
            </TabPane>)}

          </TabContent>
        </div>
        <div className="actions clearfix">
          <ul>
            <li
              className={
                activeTab === 0 ? "previous disabled" : "previous"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  toggleTab(activeTab - 1)
                }}
              >
                Previous
              </Link>
            </li>
            <li
              className={activeTab +1 == tabs.length  ? "Submit" : "next"}
            >
              <Link
                to="#"
                onClick={() => {
                  if (activeTab  + 1 == (tabs.length )) {
                    handleSubmit()
                  } else {
                    toggleTab(activeTab + 1)
                  }

                }}
              >
                {activeTab +1 == tabs.length  ? "Submit" : "next"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </CardBody>
  </Card>
}
