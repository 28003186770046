import {SUBSCRIPTION_GET_REQUEST,
  SUBSCRIPTION_GET_SUCCESS,
  SUBSCRIPTION_GET_FAILURE,
  SUBSCRIPTION_FIND_REQUEST,
  SUBSCRIPTION_FIND_SUCCESS,
  SUBSCRIPTION_FIND_FAILURE,
  SUBSCRIPTION_ACTION_REQUEST,
  SUBSCRIPTION_ACTION_SUCCESS,
  SUBSCRIPTION_ACTION_FAILURE} from './subscriptionTypes';

const INIT = {
  subscriptions: [],
  subscription: false,
  loading: false,
  error: ''
}

const AdminSubscriptions = (state = INIT, action) => {

  switch (action.type){

    case SUBSCRIPTION_GET_REQUEST: return {...state, loading: true, error: ''}
    case SUBSCRIPTION_GET_SUCCESS: return {...state, loading: false, error: '', subscriptions: action.payload}
    case SUBSCRIPTION_GET_FAILURE: return {...state, loading: false, error: action.payload}

    case SUBSCRIPTION_FIND_REQUEST: return {...state, loading: true, error: ''}
    case SUBSCRIPTION_FIND_SUCCESS: return {...state, loading: false, error: '', subscription: action.payload}
    case SUBSCRIPTION_FIND_FAILURE: return {...state, loading: false, error: action.payload}

    case SUBSCRIPTION_ACTION_REQUEST: return {...state, loading: true, error: ''}
    case SUBSCRIPTION_ACTION_SUCCESS: return {...state, loading: false, error: ''}
    case SUBSCRIPTION_ACTION_FAILURE: return {...state, loading: false, error: action.payload}

    default : return  {...state}
  }

}


export default AdminSubscriptions
