import axios from "axios"
import {
  ADMIN_LESSON_GET_REQUEST,
  ADMIN_LESSON_GET_SUCCESS,
  ADMIN_LESSON_GET_FAILURE,
  ADMIN_LESSON_FIND_REQUEST,
  ADMIN_LESSON_FIND_SUCCESS,
  ADMIN_LESSON_FIND_FAILURE,
  ADMIN_LESSON_ACTION_REQUEST,
  ADMIN_LESSON_ACTION_SUCCESS,
  ADMIN_LESSON_ACTION_FAILURE
} from "./adminLessonTypes"

export const adminLessonGet = () => dispatch => {
  dispatch({ type: ADMIN_LESSON_GET_REQUEST })
  axios.get(`admin/lesson/get`)
    .then(({ data }) => dispatch({ type: ADMIN_LESSON_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_LESSON_GET_FAILURE, payload: message }))
}

export const adminLessonFind = (id) => dispatch => {
  dispatch({ type: ADMIN_LESSON_FIND_REQUEST })
  axios.get(`admin/lesson/get/${id}`)
    .then(({ data }) => dispatch({ type: ADMIN_LESSON_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_LESSON_FIND_FAILURE, payload: message }))
}

export const adminLessonApprove = (id) => dispatch => {
  dispatch({ type: ADMIN_LESSON_ACTION_REQUEST })
  axios.post(`admin/lesson/approve`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_LESSON_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_LESSON_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(adminLessonGet())
      dispatch(adminLessonFind(id))
    })
}

export const adminLessonReject = (id) => dispatch => {
  dispatch({ type: ADMIN_LESSON_ACTION_REQUEST })
  axios.post(`admin/lesson/reject`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_LESSON_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_LESSON_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(adminLessonGet())
      dispatch(adminLessonFind(id))
    })
}

export const adminLessonDelete = (id) => dispatch => {
  dispatch({ type: ADMIN_LESSON_ACTION_REQUEST })
  axios.post(`admin/lesson/delete`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_LESSON_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_LESSON_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(adminLessonGet())
      dispatch(adminLessonFind(id))
    })
}
