import { Modal } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { adminMPesaFind } from "../../store/admin/Mpesa/adminMpesaActions"
import { Pre } from "../../components/dev/pre"
import moment from "moment"


export const MPesaDetailModal = ({ id, isOpen, toggle }) => {
  const dispatch = useDispatch()
  const { MPesa } = useSelector(state => state)
  const { record, loading } = MPesa


  const get = () => dispatch(adminMPesaFind(id))

  useEffect(() => {
    if (id && isOpen) {
      get()
    }
  }, [isOpen, id])

  const { info, subscription, customer, trainer, payment } = record
  return <Modal
    isOpen={isOpen}
    toggle={toggle}
  >
    <>
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          id="exampleModalFullscreenLabel"
        >
          {loading ? "Loading" : info && `${info.TransID} | KES ${info.TransAmount} | ${info.names} `}
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {record &&
      <div className="modal-body">
        <table className="table ">
          <tbody>
          <tr>
            <th>Transaction Type</th>
            <td>{info.TransactionType}</td>
          </tr>
          <tr>
            <th>MPesa No</th>
            <td>{info.MSISDN}</td>
          </tr>
          <tr>
            <th>Pay Bill</th>
            <td>{info.BusinessShortCode}</td>
          </tr>
          <tr>
            <th>A/C</th>
            <td>{info.BillRefNumber}</td>
          </tr>
          <tr>
            <th>Amount</th>
            <td>KES {info.TransAmount}</td>
          </tr>
          <tr>
            <th>Payment Date</th>
            <td>{info && moment(info.created_at).format("Do MMM YYYY HH:mm")}</td>
          </tr>
          </tbody>
        </table>
        <Pre data={record}> </Pre>

      </div>
      }

    </>
  </Modal>
}
