import axios from "axios"
import {
  ADMIN_MPESA_GET_REQUEST,
  ADMIN_MPESA_GET_SUCCESS,
  ADMIN_MPESA_GET_FAILURE,
  ADMIN_MPESA_FIND_REQUEST,
  ADMIN_MPESA_FIND_SUCCESS,
  ADMIN_MPESA_FIND_FAILURE,
  ADMIN_MPESA_ACTION_REQUEST,
  ADMIN_MPESA_ACTION_SUCCESS,
  ADMIN_MPESA_ACTION_FAILURE
} from "./adminMpesaTypes"

export const adminMPesaGet = () => dispatch => {
  dispatch({ type: ADMIN_MPESA_GET_REQUEST })
  axios.get(`admin/mpesa/get`)
    .then(({ data }) => dispatch({ type: ADMIN_MPESA_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_MPESA_GET_FAILURE, payload: message }))
}

export const adminMPesaFind = (id) => dispatch => {
  dispatch({ type: ADMIN_MPESA_FIND_REQUEST })
  axios.get(`admin/mpesa/get/${id}`)
    .then(({ data }) => dispatch({ type: ADMIN_MPESA_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_MPESA_FIND_FAILURE, payload: message }))
}

export const adminMPesaDelete = (id) => dispatch => {
  dispatch({ type: ADMIN_MPESA_ACTION_REQUEST })
  axios.post(`admin/mpesa/delete`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_MPESA_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_MPESA_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(adminMPesaGet())
      dispatch(adminMPesaFind(id))
    })
}
