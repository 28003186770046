

export const VIDEO_GET_REQUEST = 'VIDEO_GET_REQUEST';
export const VIDEO_GET_SUCCESS = 'VIDEO_GET_SUCCESS';
export const VIDEO_GET_FAILURE = 'VIDEO_GET_FAILURE';

export const VIDEO_FIND_REQUEST = 'VIDEO_FIND_REQUEST';
export const VIDEO_FIND_SUCCESS = 'VIDEO_FIND_SUCCESS';
export const VIDEO_FIND_FAILURE = 'VIDEO_FIND_FAILURE';

export const VIDEO_UPLOAD_REQUEST = 'VIDEO_UPLOAD_REQUEST';
export const VIDEO_UPLOAD_SUCCESS = 'VIDEO_UPLOAD_SUCCESS';
export const VIDEO_UPLOAD_FAILURE = 'VIDEO_UPLOAD_FAILURE';

export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAILURE = 'VIDEO_DELETE_FAILURE';
