import React from "react"
import { Link } from "react-router-dom"
import { Image } from "./image"


export const UserChip = ({ id, name, plain, email, avatar }) => {
  return <Link to={`/admin/users/${id}`}
               className={plain ? "me-2 p-1 pe-1" : "bg-secondary border-1 me-2 p-1 pe-1 rounded-5"}>
    <span>
      <Image
        src={avatar}
        alt=""
        width={20}
        className="rounded-5 "
      />
      {" "}<span className={!plain ? "text-white" : "bold"}>{name}</span></span>
    {" "}
  </Link>
}
