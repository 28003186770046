import { Badge } from "reactstrap"
import React from "react"


export const UserStatus = ({ status }) => {
  switch (status) {
    case "unverified":
      return <Badge className="me-2 font-size-12 text-warning bg-soft bg-warning text-capitalize "> {status} </Badge>

    case "active":
      return <Badge className="me-2 font-size-12 text-success bg-soft bg-success text-capitalize"> {status} </Badge>

    case "blocked":
      return <Badge className="me-2 font-size-12 text-danger bg-soft bg-danger text-capitalize"> {status} </Badge>

    default:
      return <Badge className="me-2 font-size-12 text-warning bg-soft bg-warning text-capitalize"> {status} </Badge>
  }
}

export const CourseStatus = ({ status }) => {
  switch (status) {
    case "draft":
      return <Badge className="me-2 font-size-14  bg-warning"> Draft </Badge>

    case "published":
      return <Badge className="me-2 font-size-14  bg-success"> Published </Badge>

    case "deprecated":
      return <Badge className="me-2 font-size-14  bg-danger"> Deprecated </Badge>

    default:
      return <Badge className="me-2 font-size-14  bg-default"> {status} </Badge>
  }
}

export const CourseApprovalStatus = ({ approval }) => {
  switch (approval) {
    case "pending":
      return <Badge className="me-2 font-size-14  bg-warning"> {approval.toUpperCase()} </Badge>

    case "approved":
      return <Badge className="me-2 font-size-14  bg-success"> {approval.toUpperCase()} </Badge>

    case "rejected":
      return <Badge className="me-2 font-size-14  bg-danger"> {approval.toUpperCase()} </Badge>

    default:
      return <Badge className="me-2 font-size-14  bg-default"> {approval} </Badge>
  }
}

export const LessonApprovalStatus = ({ approval }) => {
  switch (approval) {
    case "pending":
      return <Badge className="me-2 font-size-14  bg-warning"> {approval.toUpperCase()} </Badge>

    case "approved":
      return <Badge className="me-2 font-size-14  bg-success"> {approval.toUpperCase()} </Badge>

    case "rejected":
      return <Badge className="me-2 font-size-14  bg-danger"> {approval.toUpperCase()} </Badge>

    case "appealed":
      return <Badge className="me-2 font-size-14  bg-warning"> {approval.toUpperCase()} </Badge>

    default:
      return <Badge className="me-2 font-size-14  bg-default"> {approval} </Badge>
  }
}

export const LessonStatus = ({ status }) => {
  switch (status) {
    case "draft":
      return <Badge className="me-2 font-size-14  bg-warning"> Draft </Badge>

    case "published":
      return <Badge className="me-2 font-size-14  bg-success"> Published </Badge>

    case "deprecated":
      return <Badge className="me-2 font-size-14  bg-danger"> Deprecated </Badge>

    default:
      return <Badge className="me-2 font-size-14  bg-default"> {status} </Badge>
  }
}


export const TrainerStatus = ({ status, size }) => {
  switch (status) {
    case "unverified":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-default`}>{status.toUpperCase()}</Badge>
    case "verified":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-success`}>{status.toUpperCase()}</Badge>
    case "rejected":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-warning`}>{status.toUpperCase()}</Badge>
    case "blocked":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-danger`}>{status.toUpperCase()}</Badge>

    default:
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-default`}> {status.toUpperCase()}</Badge>
  }
}

//'pending payment','active','expired','incomplete payment','pending'
export const SubscriptionStatus = ({ status, size }) => {
  switch (status) {
    case "pending payment":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-default`}>{status.toUpperCase()}</Badge>
    case "active":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-success`}>{status.toUpperCase()}</Badge>
    case "pending":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-default`}>{status.toUpperCase()}</Badge>
    case "expired":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-danger`}>{status.toUpperCase()}</Badge>
    case "incomplete payment":
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-warning`}>{status.toUpperCase()}</Badge>

    default:
      return <Badge
        className={`me-2 ${size === "sm" ? "font-size-11" : "font-size-14"}  bg-default`}> {status.toUpperCase()}</Badge>
  }
}


