import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Container, FormGroup, Input, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { subscriptionGet } from "../../store/subscriptions/subscriptionActions"
import { Pre } from "../../components/dev/pre"
import { UserChip } from "../../components/app/userComponents"
import moment from "moment"
import { SubscriptionStatus } from "../../components/app/statusBudges"

const TrainerSubscriptions = () => {
  const dispatch = useDispatch()
  const { Subscriptions } = useSelector(state => state)
  const [search, setSearch] = useState("")
  //meta title
  document.title = "App Skill | Trainer | Subscriptions"
  const { subscriptions, loading } = Subscriptions
  const get = () => dispatch(subscriptionGet())
  useEffect(get, [])
  const columns = [
    {
      name: "Subscriber",
      selector: ({ FirstName }) => FirstName,
      width: "220px",
      sortable: true,

      cell: ({ subscriber, subscriber_name, subscriber_avatar }) => <UserChip plain id={subscriber} avatar={subscriber_avatar} name={subscriber_name} />
    },
    {
      name: "Amount (KES)",
      style: { fontFamily: "Consolas" },
      selector: ({ amount }) => amount,
      sortable: true,
      right:true,
    },
    {
      name: "Paid (KES)",
      style: { fontFamily: "Consolas" },
      selector: ({ paid }) => paid,
      sortable: true,
      right:true,
    },
    {
      name: "Commencement",
      style: { fontFamily: "Consolas" },
      wrap: true,
      selector: ({ commencement_date }) => moment(commencement_date).format('Do MMM YYYY'),
      sortable: true,
    },
    {
      name: "Expiry",
      style: { fontFamily: "Consolas" },
      wrap: true,
      selector: ({ commencement_date }) => moment(commencement_date).format('Do MMM YYYY'),
      sortable: true,
    },
    {
      name: "Status",
      wrap: true,
      selector: ({ status }) => <SubscriptionStatus status={status} size={'sm'} />,
      sortable: true,
    },
    // {
    //   name: "MPESA REF",
    //   style: { fontFamily: "Consolas" },
    //   wrap: true,
    //   selector: ({ TransID }) => TransID,
    //   sortable: true
    // },
    // {
    //   name: "PAYBILL",
    //   style: { fontFamily: "Consolas" },
    //   selector: ({ BusinessShortCode }) => BusinessShortCode,
    //   sortable: true,
    //   omit: true
    // },
    // {
    //   name: "ACCOUNT",
    //   style: { fontFamily: "Consolas" },
    //   selector: ({ BillRefNumber }) => BillRefNumber,
    //   sortable: true
    // },
    // { name: "PHONE", style: { fontFamily: "Consolas" }, selector: ({ MSISDN }) => MSISDN, sortable: true },
    // { name: "Amount", style: { fontFamily: "Consolas" }, selector: ({ TransAmount }) => TransAmount, sortable: true },
    // {
    //   name: "Transaction Date",
    //   right: true,
    //   width: "220px",
    //   style: { fontFamily: "Consolas" },
    //   selector: ({ created_at }) => moment(created_at).format("Do, MMM YYYY HH:mm:ss"),
    //   sortable: true
    // }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />
          <Card>
            <CardBody>
              <FormGroup className="input-group inline float-end">
                <Input placeholder="search" value={search} onChange={e => setSearch(e.target.value)} />
                <Button className="" size="sm" outline color={"primary"} onClick={get}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <i className="mbi mdi mdi-reload"></i>}
                  {" "} Reload
                </Button>
              </FormGroup>
            </CardBody>
            <Datatable columns={columns}
                       data={subscriptions.filter(row => JSON.stringify(row)
                         .toLowerCase()
                         .includes(search.toLowerCase()))}
                       pagination
            />

            <Pre data={subscriptions} />
          </Card>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default TrainerSubscriptions
