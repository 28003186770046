export const SUBSCRIPTION_GET_REQUEST = 'SUBSCRIPTION_GET_REQUEST';
export const SUBSCRIPTION_GET_SUCCESS = 'SUBSCRIPTION_GET_SUCCESS';
export const SUBSCRIPTION_GET_FAILURE = 'SUBSCRIPTION_GET_FAILURE';

export const SUBSCRIPTION_FIND_REQUEST = 'SUBSCRIPTION_FIND_REQUEST';
export const SUBSCRIPTION_FIND_SUCCESS = 'SUBSCRIPTION_FIND_SUCCESS';
export const SUBSCRIPTION_FIND_FAILURE = 'SUBSCRIPTION_FIND_FAILURE';

export const SUBSCRIPTION_ACTION_REQUEST = 'SUBSCRIPTION_ACTION_REQUEST';
export const SUBSCRIPTION_ACTION_SUCCESS = 'SUBSCRIPTION_ACTION_SUCCESS';
export const SUBSCRIPTION_ACTION_FAILURE = 'SUBSCRIPTION_ACTION_FAILURE';

