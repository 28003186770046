export const ADMIN_PERMISSIONS_GET_REQUEST = 'ADMIN_PERMISSIONS_GET_REQUEST';
export const ADMIN_PERMISSIONS_GET_SUCCESS = 'ADMIN_PERMISSIONS_GET_SUCCESS';
export const ADMIN_PERMISSIONS_GET_FAILURE = 'ADMIN_PERMISSIONS_GET_FAILURE';

export const ADMIN_PERMISSIONS_FIND_REQUEST = 'ADMIN_PERMISSIONS_FIND_REQUEST';
export const ADMIN_PERMISSIONS_FIND_SUCCESS = 'ADMIN_PERMISSIONS_FIND_SUCCESS';
export const ADMIN_PERMISSIONS_FIND_FAILURE = 'ADMIN_PERMISSIONS_FIND_FAILURE';

export const ADMIN_PERMISSIONS_ACTION_REQUEST = 'ADMIN_PERMISSIONS_ACTION_REQUEST';
export const ADMIN_PERMISSIONS_ACTION_SUCCESS = 'ADMIN_PERMISSIONS_ACTION_SUCCESS';
export const ADMIN_PERMISSIONS_ACTION_FAILURE = 'ADMIN_PERMISSIONS_ACTION_FAILURE';

