export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAILURE = 'USER_GET_FAILURE';


export const USER_FIND_REQUEST = 'USER_FIND_REQUEST';
export const USER_FIND_SUCCESS = 'USER_FIND_SUCCESS';
export const USER_FIND_FAILURE = 'USER_FIND_FAILURE';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const USER_ACTION_REQUEST = 'USER_ACTION_REQUEST';
export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS';
export const USER_ACTION_FAILURE = 'USER_ACTION_FAILURE';



