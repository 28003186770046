import axios from "axios"
import {
  VIDEO_DELETE_FAILURE,
  VIDEO_DELETE_REQUEST, VIDEO_DELETE_SUCCESS,
  VIDEO_FIND_FAILURE,
  VIDEO_FIND_REQUEST,
  VIDEO_FIND_SUCCESS,
  VIDEO_GET_FAILURE,
  VIDEO_GET_REQUEST,
  VIDEO_GET_SUCCESS, VIDEO_UPLOAD_FAILURE, VIDEO_UPLOAD_REQUEST, VIDEO_UPLOAD_SUCCESS
} from "./videoTypes"


export const videoGet = () => dispatch => {
  dispatch({type: VIDEO_GET_REQUEST});
  axios.get(`app/video/get`)
    .then(res => dispatch({type: VIDEO_GET_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: VIDEO_GET_FAILURE, payload: res.message}))
}

export const videoFind = (id) => dispatch => {
  dispatch({type: VIDEO_FIND_REQUEST});
  axios.get(`app/video/get/${id}`)
    .then(res => dispatch({type: VIDEO_FIND_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: VIDEO_FIND_FAILURE, payload: res.message}))
}

export const videoUpload = (fields, callBack) => dispatch => {
  dispatch({type: VIDEO_UPLOAD_REQUEST});
  axios.post(`app/video/submit`, fields)
    .then(res => dispatch({type: VIDEO_UPLOAD_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: VIDEO_UPLOAD_FAILURE, payload: res.message}))
    .finally(() => callBack ? callBack : dispatch(videoGet()))
}


export const videoDelete = (id, callBack) => dispatch => {
  dispatch({type: VIDEO_DELETE_REQUEST});
  axios.post(`app/video/delete/${id}`)
    .then(res => dispatch({type: VIDEO_DELETE_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: VIDEO_DELETE_FAILURE, payload: res.message}))
    .finally(() => callBack ? callBack : dispatch(videoGet()))

}




