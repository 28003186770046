export const ADMIN_SUBSCRIPTION_GET_REQUEST = 'ADMIN_SUBSCRIPTION_GET_REQUEST';
export const ADMIN_SUBSCRIPTION_GET_SUCCESS = 'ADMIN_SUBSCRIPTION_GET_SUCCESS';
export const ADMIN_SUBSCRIPTION_GET_FAILURE = 'ADMIN_SUBSCRIPTION_GET_FAILURE';

export const ADMIN_SUBSCRIPTION_FIND_REQUEST = 'ADMIN_SUBSCRIPTION_FIND_REQUEST';
export const ADMIN_SUBSCRIPTION_FIND_SUCCESS = 'ADMIN_SUBSCRIPTION_FIND_SUCCESS';
export const ADMIN_SUBSCRIPTION_FIND_FAILURE = 'ADMIN_SUBSCRIPTION_FIND_FAILURE';

export const ADMIN_SUBSCRIPTION_ACTION_REQUEST = 'ADMIN_SUBSCRIPTION_ACTION_REQUEST';
export const ADMIN_SUBSCRIPTION_ACTION_SUCCESS = 'ADMIN_SUBSCRIPTION_ACTION_SUCCESS';
export const ADMIN_SUBSCRIPTION_ACTION_FAILURE = 'ADMIN_SUBSCRIPTION_ACTION_FAILURE';

