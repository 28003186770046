import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Container, FormGroup, Input, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { adminVideoGet } from "../../store/admin/videos/adminVideoActions"
import { Pre } from "../../components/dev/pre"
import { Image } from "../../components/app/image"
import { UserChip } from "../../components/app/userComponents"
import { humanFileSize } from "../../components/app/sizeformat"
import moment from "moment"
import { VideoDetailModal } from "./videoDetailModal"
import { Link } from "react-router-dom"

const AdminVideos = () => {
  const dispatch = useDispatch()
  const { AdminVideos } = useSelector(state => state)
  const { loading, videos } = AdminVideos
  const [video, setVideo] = useState(false)
  const [modal, setModal] = useState(false)
  const [search, setSearch] = useState("")
  const get = () => dispatch(adminVideoGet())
  const toggle = () => {
    setModal(video ? true : false)
  }

  useEffect(get, [])

  useEffect(toggle, [video])

  //{
  //    "id": 81,
  //    "uploads": 81,
  //    "title": "ngwelo.mp4",
  //    "video": "http://127.0.0.1:8080/api/file/?id=69",
  //    "duration": 0,
  //    "created_at": "2023-09-23T16:46:06.000000Z",
  //    "updated_at": "2023-09-27T10:54:17.000000Z",
  //    "url": "http://api.appskill.africa/api/file/?id=81",
  //    "filesize": 1609706,
  //    "user": 1,
  //    "type": "video/mp4",
  //    "ext": "mp4",
  //    "name": "Kelvin Kinja Mwangi",
  //    "avatar": "https://trainer.appskill.africa/avatar.jpg",
  //    "course_title": null,
  //    "lesson_title": null
  //   },
  const columns = [
    { name: "", width: "50px", cell: ({ thumbnail }) => <Image src={thumbnail} className="shadow-sm" width={40} /> },
    {
      name: "Video",
      sortable: true,
      width: "30%",
      selector: ({ title }) => title,
      cell: ({ id, title, user, avatar, name }) => <div>
        <Link onClick={() => setVideo(id)}><strong>{title}</strong></Link>
      </div>
    },
    {
      name: "Uploaded By",
      sortable: true,
      wrap: true,
      selector: ({ user }) => user,
      cell: ({ user, avatar, name }) => <div><UserChip plain name={name} avatar={avatar} id={user} /></div>
    },
    {
      name: "Duration",
      sortable: true, width: "120px", center: true,
      selector: ({ id }) => id
    },
    {
      name: "Size",
      sortable: true,
      width: "120px", center: true,
      selector: ({ filesize }) => humanFileSize(filesize, true, 0)
    },

    {
      name: "Uploaded",
      sortable: true,
      right: true,
      cell: ({ created_at }) => moment(created_at).format("Do MMM YYYY HH:mm")

    }


  ]


  //meta title
  document.title = "App Skill | Admin | Videos"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admin" breadcrumbItem="Videos" />
          <Card>
            <CardBody>
              <FormGroup className="input-group inline float-end">
                <Input placeholder="Search Videos..." value={search} onChange={e => setSearch(e.target.value)} />
                <Button className="" size="sm" outline color={"primary"} onClick={get}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <i className="mbi mdi mdi-reload"></i>}
                  {" "} Reload
                </Button>
              </FormGroup>
            </CardBody>

            <Datatable
              columns={columns}
              data={videos.filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()))}
              pagination
            />

          </Card>
          <VideoDetailModal toggle={() => setVideo(false)} isOpen={modal} videoID={video} />

        </Container>
      </div>
    </React.Fragment>
  )
}

export default AdminVideos
