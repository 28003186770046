import React from "react"
import Datatable from "react-data-table-component"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Button, ButtonGroup } from "reactstrap"
import { LessonApprovalStatus } from "../../../components/app/statusBudges"
import { adminLessonApprove, adminLessonReject } from "../../../store/admin/lessons/adminLessonActions"
import { Image } from "../../../components/app/image"


export const LessonsList = ({ lessons, loading, onClick, omits, hideCourse, hideCategory }) => {

  const dispatch = useDispatch()
  const columns = [
    {
      name: "",
      selector: "id",
      width: "90px",
      cell: (row) => <Image
        width={70} height={70} src={row.video_thumbnail ? row.video_thumbnail : (row.video ? row.thumbnail : null)}
        className="rounded-1 shadow-sm m-2" />
    },
    {
      name: "Lesson Name",
      wrap: true,
      selector: "title",
      sortable: true,
      minWidth: "30%",
      cell: (row) => <div>
        <div>
          <Link onClick={() => onClick(row.id)} className="text-dark font-size-17">{row.title}</Link>
        </div>
        <div className="m-0 p-0">
          {!hideCourse && <strong>
            <Link to={`/course/course/${row.course}`}>{row.category_title}</Link>
            {" | "}
            <Link to={`/course/course/${row.course}`}>{row.course_title}</Link>
          </strong>}
          <span>

        </span>
        </div>
      </div>
    },
    {
      name: "Traffic",
      omit: (omits.indexOf("traffic") >= 0),
      selector: "views",
      sortable: true,
      cell: ({ likes, views, comments }) => <>
        <i className="mdi mdi-eye"></i>{" "}{views}{" | "}
        <i className="bx bx-comment"></i>{" "}{comments}{" | "}
        <i className="bx bx-like"></i>{" "}{likes}{" "} </>
    },
    {
      name: "Duration",
      right: true,
      selector: "duration",
      sortable: true,
      omit: (omits.indexOf("duration") >= 0),

      cell: ({ approval }) => <>{Math.round(Math.random() * 100)} Minutes</>
    }, {
      name: "Size",
      right: true,
      selector: "size",
      omit: (omits.indexOf("size") >= 0),
      sortable: true,
      cell: ({ approval }) => <>{Math.round(Math.random() * 100)} MB</>
    },
    {
      name: "Status",
      width: 180,
      right: true,
      selector: "approval",
      omit: (omits.indexOf("approval") >= 0),
      sortable: true,
      cell: ({ approval }) => <LessonApprovalStatus approval={approval} />
    },
    {
      name: "",
      right: true,
      selector: "id",
      width: "180px",
      omit: (omits.indexOf("actions") >= 0),
      cell: ({ id, approval }) => <><ButtonGroup className="align-items-end float-end ">
        <Button size="sm" color="danger"
                onClick={() => dispatch(adminLessonReject(id))}
                outline>
          <i className="mdi mdi-check-circle text-danger " />{" "}

          Reject</Button>
        <Button size="sm" color="success"
                onClick={() => dispatch(adminLessonApprove(id))}
                outline>
          <i className="bx bx-badge-check text-success " />{" "}

          Approve</Button>

      </ButtonGroup></>
    }


  ]

  return <>
    <Datatable columns={columns} data={lessons} pagination />



  </>
}
