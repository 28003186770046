export const ADMIN_LESSON_GET_REQUEST = 'ADMIN_LESSON_GET_REQUEST';
export const ADMIN_LESSON_GET_SUCCESS = 'ADMIN_LESSON_GET_SUCCESS';
export const ADMIN_LESSON_GET_FAILURE = 'ADMIN_LESSON_GET_FAILURE';

export const ADMIN_LESSON_FIND_REQUEST = 'ADMIN_LESSON_FIND_REQUEST';
export const ADMIN_LESSON_FIND_SUCCESS = 'ADMIN_LESSON_FIND_SUCCESS';
export const ADMIN_LESSON_FIND_FAILURE = 'ADMIN_LESSON_FIND_FAILURE';

export const ADMIN_LESSON_ACTION_REQUEST = 'ADMIN_LESSON_ACTION_REQUEST';
export const ADMIN_LESSON_ACTION_SUCCESS = 'ADMIN_LESSON_ACTION_SUCCESS';
export const ADMIN_LESSON_ACTION_FAILURE = 'ADMIN_LESSON_ACTION_FAILURE';

