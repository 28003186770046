import React, { useState } from "react"
import { Container } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useNavigate } from "react-router-dom"
import { WizardComponent } from "../../components/wizardComponent"
import CourseWizardTab from "./form/courseWizardTab"
import LessonWizardTab from "./form/lessonWizardTab"
import axios from "axios"

export const CreateCourse = () => {
  const navigate = useNavigate()

  const [course, setCourse] = useState()
  const [lessons, setLessons] = useState([])

  const submit = () => {
    axios.post(`app/course/wizard`, { course, lessons })
      .then((res) => navigate(`/course/course/${res.data.id}`))
  }


  const tabs = [
    {
      title: "Course Information",
      "component": <CourseWizardTab updateCourse={setCourse} onSubmit={(data) => setCourse(data)} />
    },
    { title: "Lessons", "component": <LessonWizardTab updateLessons={data => setLessons(data)} /> },
    { title: "Confirm", "component": <p>Everything is ok</p> }
  ]
  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <WizardComponent handleSubmit={submit} title={"Create Course"} tabs={tabs} />
      </Container>
    </div>
  </React.Fragment>

}
