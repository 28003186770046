import axios from "axios"
import {
  ADMIN_PERMISSIONS_ACTION_FAILURE,
  ADMIN_PERMISSIONS_ACTION_REQUEST, ADMIN_PERMISSIONS_ACTION_SUCCESS,
  ADMIN_PERMISSIONS_FIND_FAILURE,
  ADMIN_PERMISSIONS_FIND_REQUEST, ADMIN_PERMISSIONS_FIND_SUCCESS,
  ADMIN_PERMISSIONS_GET_FAILURE,
  ADMIN_PERMISSIONS_GET_REQUEST,
  ADMIN_PERMISSIONS_GET_SUCCESS
} from "./permissionTypes"
import { roleFind } from "../roles/roleActions"
import toastr from "toastr"


export const permissionGet = () => dispatch => {
  dispatch({ type: ADMIN_PERMISSIONS_GET_REQUEST })
  axios.get("/admin/permissions/get")
    .then(({ data }) => dispatch({ type: ADMIN_PERMISSIONS_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_PERMISSIONS_GET_FAILURE, payload: message }))
}


export const permissionFind = (id) => dispatch => {
  dispatch({ type: ADMIN_PERMISSIONS_FIND_REQUEST })
  axios.get(`/admin/permissions/get/${id}`)
    .then(({ data }) => dispatch({ type: ADMIN_PERMISSIONS_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_PERMISSIONS_FIND_FAILURE, payload: message }))
}

export const permissionUpdate = (data) => dispatch => {
  dispatch({ type: ADMIN_PERMISSIONS_ACTION_REQUEST })
  axios.post(`admin/permissions/update`, data)
    .then((res) => dispatch(permissionGet()))
    .catch(({ message }) => dispatch({ type: ADMIN_PERMISSIONS_ACTION_FAILURE, payload: message }))
}

export const permissionAssignRole = (role, permission) => dispatch => {
  dispatch({ type: ADMIN_PERMISSIONS_ACTION_REQUEST })
  axios.post(`admin/permissions/assign/role`, { role, permission })
    .then((res) => {
      dispatch({ type: ADMIN_PERMISSIONS_ACTION_SUCCESS })
      dispatch(roleFind(role))
      toastr.success("Changes Saved")
    })
    .catch(({ message }) => dispatch({ type: ADMIN_PERMISSIONS_ACTION_FAILURE, payload: message }))


}

