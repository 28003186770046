import React, { useEffect, useRef } from "react"
import { Input } from "reactstrap"
import { Button } from "reactstrap"

const Canvas = props => {
  const ref = useRef()

  const draw = (context) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = "#ff0000"
    context.fillRect(0, 0,  200, 200)
  }

  useEffect(() => {
    const canvas = ref.current
    const context = canvas.getContext("2d")
    draw(context)



  }, [])

  return<div className="visually-hidden">
    <canvas {...props} ref={ref} />
    <Button
      onClick={() => console.log(ref.current?.toDataURL())}>

    </Button>
  </div>


}


export default Canvas


