import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container} from 'reactstrap';
import {Player} from 'video-react';

import 'video-react/dist/video-react.css';
import {videoFind} from '../../store/video/videoActions';
import {Pre} from '../../components/dev/pre';
import VideoSnapshot from 'video-snapshot';

export const VideoPlayer = ({video}) => {
    return <video
        src={video}
        style={{width: '100%'}}
        // ref={videoRef}
        controls
    />;
};


const PlayVideo = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {Videos} = useSelector(state => state);
    const {video} = Videos;
    const get = () => dispatch(videoFind(id));
    useEffect(() => {
        get();
    }, []);


    const onChange = (e) => {
        // const source = new VideoSnapshot(video.info.video);
        // source.takeSnapshot(1).then(console.log).catch(console.log);

        // const previewSrc = source.takeSnapshot(0);

        // console.log(previewSrc);
        //
        // const img = document.createElement('img');
        //
        // img.src = previewSrc;
        //
        // document.body.appendChild(img);
    };


    return <React.Fragment>
        <div className="page-content">
            <Container fluid>

                {video && <VideoPlayer
                    playsInline
                    video={video.info.video}/>}

            </Container>
        </div>

    </React.Fragment>;


};

export default PlayVideo;

