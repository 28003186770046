import {
  VIDEO_DELETE_FAILURE,
  VIDEO_DELETE_REQUEST, VIDEO_DELETE_SUCCESS,
  VIDEO_FIND_FAILURE,
  VIDEO_FIND_REQUEST,
  VIDEO_FIND_SUCCESS,
  VIDEO_GET_FAILURE,
  VIDEO_GET_REQUEST,
  VIDEO_GET_SUCCESS, VIDEO_UPLOAD_FAILURE, VIDEO_UPLOAD_REQUEST, VIDEO_UPLOAD_SUCCESS
} from "./videoTypes"


const INIT_STATE = {
  videos: [],
  video: false,
  loading: false,
  error: null
}

const Videos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VIDEO_GET_REQUEST :
      return { ...state, loading: true, error: null }
    case VIDEO_GET_SUCCESS :
      return { ...state, loading: false, videos: action.payload }
    case VIDEO_GET_FAILURE :
      return { ...state, loading: false, error: action.payload }

    case VIDEO_FIND_REQUEST :
      return { ...state, loading: true, error: null }
    case VIDEO_FIND_SUCCESS :
      return { ...state, loading: false, video: action.payload }
    case VIDEO_FIND_FAILURE :
      return { ...state, loading: false, error: action.payload }

    case VIDEO_UPLOAD_REQUEST :
      return { ...state, loading: true, error: null }
    case VIDEO_UPLOAD_SUCCESS :
      return { ...state, loading: false }
    case VIDEO_UPLOAD_FAILURE :
      return { ...state, loading: false, error: action.payload }

    case VIDEO_DELETE_REQUEST :
      return { ...state, loading: true, error: null }
    case VIDEO_DELETE_SUCCESS :
      return { ...state, loading: false }
    case VIDEO_DELETE_FAILURE :
      return { ...state, loading: false, error: action.payload }


    default :
      return { ...state }
  }


}

export default Videos
