import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";



import Users from "./admin/users/userReducer";
import Trainers from "./admin/trainer/trainerReducer";
import AdminCourses from "./admin/courses/adminCourseReducer";
import AdminLessons from "./admin/lessons/adminLessonReducer";
import AdminVideos from "./admin/videos/adminVideoReducer";
import MPesa from "./admin/Mpesa/adminMpesaReducer";
import AdminSubscriptions from "./admin/subscriptions/adminSubscriptionReducer";
import Roles from './admin/roles/roleReducer'
import Permissions from './admin/permissions/permissionReducer'


import Subscriptions from './subscriptions/subscriptionReducer'
import Courses from "./courses/courseReducer";
import Lessons from "./lessons/lessonReducer";
import Bins from "./bin/binReducer";
import Videos from "./video/videoReducer";
import Authentication from "./authentication/authReducer"


const rootReducer = combineReducers({
  // public
  Courses,
  Bins,
  Lessons,
  Videos,
  Authentication,
  Subscriptions,

  // ADMIN

  Users,
  Trainers,
  AdminCourses,
  AdminLessons,
  AdminVideos,
  MPesa,
  AdminSubscriptions,
  Roles,
  Permissions,



  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob
});

export default rootReducer;
