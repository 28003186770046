import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

const AuthMiddleware = (props) => {
  const { Authentication } = useSelector(state => state)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {

    setLoggedIn(Authentication.loggedIn)
  }, [Authentication])


  return (<React.Fragment>
    {props.children}
    {/*{loggedIn ? props.children : <Navigate to={{ pathname: "/", state: { from: props.location } }} />}*/}
  </React.Fragment>)
}

export default AuthMiddleware
