import React, { useEffect, useState } from "react"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row, TabContent,
  Table, TabPane, UncontrolledTooltip,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { lessonFind, lessonPublish, lessonSubmit } from "../../store/lessons/lessonActions"
import { courseFind, coursePublish } from "../../store/courses/courseActions"
import { CourseStatus } from "../../components/app/statusBudges"
import moment from "moment"
import { LessonListComponent } from "./components/lessonList"
import LessonFormCanvas from "./form/lessonFormCanvas"
import axios from 'axios';

const LessonDetails = () => {

  const dispatch = useDispatch()
  const { Lessons } = useSelector(state => state)
  const { lesson } = Lessons
  const { id } = useParams()
  const [form, setForm] = useState(false)
  const [active, setActive] = useState('information')
  const [message, setMessage] = useState('')
  const get = () => dispatch(lessonFind(id))
  const [loading, setLoading] = useState(false)
  const sendMessage = () => {
    setLoading(true)
    const data = {lesson: id, message}
    setMessage("")
    axios.post(`app/comment/submit`, data)
        .then(() => dispatch(lessonFind(id)))
        .catch(handleError)
        .finally(() => setLoading(false))
  }

  useEffect(get, [])

  document.title = "Lesson Details"

  return (
    <React.Fragment>
      <div className="page-content">
        {lesson && <Container fluid>
          <Breadcrumbs
            title={lesson.course.title}
            breadcrumbItem={lesson.info.title} />
          <Row>
            <Col sm={5}>
              <Card>
                {lesson && lesson.video && <img src={lesson.video.thumbnail} />}
                <CardBody>
                  <div className="justify-content-center text-center mb-5 align-content-center align-items-center">
                    <ButtonGroup className="align-self-center">
                      <Button outline color="primary" onClick={() => {
                        setForm(true)

                      }}><i className="bx bx-edit"></i> Edit</Button>
                      {lesson.info.status !== 'published' &&  <Button outline color="success"
                                                                      onClick={() =>
                                                                        dispatch(lessonPublish(lesson.info.id, get))}
                      > <i className="bx bx-badge-check" />{" "}
                        Publish</Button>}

                      <Button outline color="danger" onClick={() => {
                        // setForm(true)

                      }}><i className="bx bx-trash"></i> Delete</Button>

                    </ButtonGroup>
                  </div>
                  <Table>
                    <tbody>
                    <tr>
                      <th>TITLE</th>
                      <td>{lesson && lesson.info.title}</td>
                    </tr>
                    <tr>
                      <th>COURSE</th>
                      <td>{lesson && lesson.course.title}</td>
                    </tr>
                    <tr>
                      <th>CATEGORY</th>
                      <td>{lesson && lesson.category.title}</td>
                    </tr>
                    <tr>
                      <th>TRAINER</th>
                      <td>{lesson && lesson.trainer && lesson.trainer.name}</td>
                    </tr>
                    <tr>
                      <th>STATUS</th>
                      <td><CourseStatus status={lesson.info.status} /></td>
                    </tr>
                    <tr>
                      <th>CREATED</th>
                      <td>{lesson && moment(lesson.info.created_at).fromNow()}</td>
                    </tr>
                    <tr>
                      <th>VIEWS</th>
                      <td>{lesson && lesson.views}</td>
                    </tr>
                    <tr>
                      <th>LIKES</th>
                      <td>{lesson && lesson.likes}</td>
                    </tr>
                    <tr>
                      <th>SUBSCRIBERS</th>
                      <td>{lesson && lesson.notifies}</td>
                    </tr>
                    <tr>
                      <th>COMMENTS</th>
                      <td>{lesson && lesson.comments.length}</td>
                    </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col sm={7}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">lesson Details</CardTitle>
                </CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      active={active === "information"}
                      onClick={() => setActive("information")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                      <span className="d-none d-sm-block">Information</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      active={active === "comments"}
                      onClick={() => setActive("comments")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                      <span className="d-none d-sm-block">Comments</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={active}
                  className="text-muted">

                  <TabPane tabId="information">
                    <div className="p-2">
                      <h4>Description</h4>
                      {lesson.info.about}
                      <hr />
                      <h4>Outline</h4>
                      {lesson.info.resources}
                      <hr />

                      <hr />
                      <hr />

                    </div>
                  </TabPane>
                  <TabPane tabId="comments">
                    <div className="m-5">
                      <div>
                        <div className="p-3 chat-input-section">
                          <Row>
                            <Col>
                              <div className="position-relative">
                                <input
                                    type="text"
                                    onChange={e => setMessage(e.target.value)}
                                    value={message}
                                    // value={curMessage}
                                    // onKeyPress={onKeyPress}
                                    // onChange={e => setcurMessage(e.target.value)}
                                    className="form-control chat-input"
                                    placeholder="Enter Message..."
                                />

                              </div>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                  type="button"
                                  color="primary"
                                  onClick={sendMessage}
                                  className="btn btn-primary btn-rounded chat-send w-md "
                              >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>{" "}
                                <i className="mdi mdi-send" />
                              </Button>
                            </Col>
                          </Row>
                        </div>

                        {lesson.comments.map(row => <div
                          key={`comments-${row.id}`}
                          className="d-flex py-3">
                          <div className="avatar-xs me-3">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="bx bxs-user"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-14 mb-1">
                              {row.from_name}{" "}
                              <small className="text-muted float-end">
                                {moment(row.created_at).fromNow()}
                              </small>
                            </h5>
                            <p className="text-muted">
                              {row.message}
                            </p>
                            {/*<div>*/}
                            {/*  <Link to="#" className="text-success">*/}
                            {/*    <i className="mdi mdi-reply"></i> Reply*/}
                            {/*  </Link>*/}
                            {/*</div>*/}
                          </div>
                        </div>)}

                      </div>
                    </div>

                  </TabPane>
                </TabContent>
              </Card>

            </Col>
          </Row>
          <LessonFormCanvas lessonId={id} isOpen={form} callBack={(fields) => {
            setForm(false)
            dispatch(lessonSubmit(fields))
            dispatch(lessonFind(id))
          }} toggle={() => setForm(!form)} courseId={0} />
        </Container>}
      </div>
    </React.Fragment>
  )
}

export default LessonDetails
