import React from "react"
import axios from "axios"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export default class UserRoleFormModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      roles: []
    }
  }

  componentDidMount = () => this.get()

  get = () => {
    const { user } = this.props
    this.setState({ loading: true })
    axios.post("admin/roles/user/form", user)
      .then(({ data }) => this.setState({ roles: data }))
      .catch(this.props.toggle)
      .finally(() => this.setState({ loading: false }))
  }

  check = (index) => {
    let { roles } = this.state
    roles[index].checked = !roles[index].checked
    this.setState({ roles })
  }

  render() {
    const { roles, loading } = this.state
    const { user, isOpen, toggle } = this.props
    return <React.Fragment>
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <Form onSubmit={e => {
          e.preventDefault()
          toggle(roles)
        }}>
          <ModalHeader>
            <h5
              className="modal-title mt-0"
              id="exampleModalFullscreenLabel"
            >
              Edit {user.name} Roles
              {loading && loading}
            </h5>
            <button
              onClick={() => toggle()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            {roles.map((role, index) => <div key={`role-form-modal-${role.id}`} className="form-check form-switch mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id={`input-role-form-modal-${role.id}`}
                checked={role.checked}
                onClick={e => this.check(index)}
              />
              <label
                className="form-check-label text-capitalize"
                htmlFor={`input-role-form-modal-${role.id}`}
              >
                {role.name}
              </label>
            </div>)}
          </ModalBody>
          {!loading && <ModalFooter>
            <Button type="button" outline onClick={() => toggle()} color="dark">Close</Button>
            <Button type="submit" outline color="primary">Submit</Button>
          </ModalFooter>}
        </Form>
      </Modal>

    </React.Fragment>

  }


}
