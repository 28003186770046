import {ADMIN_LESSON_GET_REQUEST,
  ADMIN_LESSON_GET_SUCCESS,
  ADMIN_LESSON_GET_FAILURE,
  ADMIN_LESSON_FIND_REQUEST,
  ADMIN_LESSON_FIND_SUCCESS,
  ADMIN_LESSON_FIND_FAILURE,
  ADMIN_LESSON_ACTION_REQUEST,
  ADMIN_LESSON_ACTION_SUCCESS,
  ADMIN_LESSON_ACTION_FAILURE} from './adminLessonTypes';

const INIT = {
  lessons: [],
  lesson: false,
  loading: false,
  error: ''
}

const AdminLessons = (state = INIT, action) => {

  switch (action.type){
    case ADMIN_LESSON_GET_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_LESSON_GET_SUCCESS: return {...state, loading: false, error: '', lessons: action.payload}
    case ADMIN_LESSON_GET_FAILURE: return {...state, loading: false, error: action.payload}

    case ADMIN_LESSON_FIND_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_LESSON_FIND_SUCCESS: return {...state, loading: false, error: '', lesson: action.payload}
    case ADMIN_LESSON_FIND_FAILURE: return {...state, loading: false, error: action.payload}

    case ADMIN_LESSON_ACTION_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_LESSON_ACTION_SUCCESS: return {...state, loading: false, error: ''}
    case ADMIN_LESSON_ACTION_FAILURE: return {...state, loading: false, error: action.payload}

    default : return  {...state}
  }


}


export default AdminLessons
