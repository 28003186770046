import {
  ADMIN_COURSE_GET_REQUEST,
  ADMIN_COURSE_GET_SUCCESS,
  ADMIN_COURSE_GET_FAILURE,
  ADMIN_COURSE_FIND_REQUEST,
  ADMIN_COURSE_FIND_SUCCESS,
  ADMIN_COURSE_FIND_FAILURE,
  ADMIN_COURSE_ACTION_REQUEST,
  ADMIN_COURSE_ACTION_SUCCESS,
  ADMIN_COURSE_ACTION_FAILURE,
} from './adminCourseTypes'

const INIT = {
  courses : [],
  course : false,
  loading: false,
  error : ''
}

const AdminCourses = (state = INIT, action ) => {
  switch (action.type) {

    case ADMIN_COURSE_GET_REQUEST : return {...state, loading: true, error: ''}
    case ADMIN_COURSE_GET_SUCCESS : return {...state, loading: false, error: '', courses: action.payload}
    case ADMIN_COURSE_GET_FAILURE : return {...state, loading: false, error: action.payload}

    case ADMIN_COURSE_FIND_REQUEST : return {...state, loading: true, error: ''}
    case ADMIN_COURSE_FIND_SUCCESS : return {...state, loading: false, error: '', course: action.payload}
    case ADMIN_COURSE_FIND_FAILURE : return {...state, loading: false, error: action.payload}

    case ADMIN_COURSE_ACTION_REQUEST : return {...state, loading: true, error: ''}
    case ADMIN_COURSE_ACTION_SUCCESS : return {...state, loading: false, error: ''}
    case ADMIN_COURSE_ACTION_FAILURE : return {...state, loading: false, error: action.payload}


    default : return {...state}
  }

}


export default AdminCourses;

