import axios from "axios"
import {
  ADMIN_VIDEO_GET_REQUEST,
  ADMIN_VIDEO_GET_SUCCESS,
  ADMIN_VIDEO_GET_FAILURE,
  ADMIN_VIDEO_FIND_REQUEST,
  ADMIN_VIDEO_FIND_SUCCESS,
  ADMIN_VIDEO_FIND_FAILURE,
  ADMIN_VIDEO_ACTION_REQUEST,
  ADMIN_VIDEO_ACTION_SUCCESS,
  ADMIN_VIDEO_ACTION_FAILURE
} from "./adminVideoTypes"

export const adminVideoGet = () => dispatch => {
  dispatch({ type: ADMIN_VIDEO_GET_REQUEST })
  axios.get(`admin/videos/get`)
    .then(({ data }) => dispatch({ type: ADMIN_VIDEO_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_VIDEO_GET_FAILURE, payload: message }))
}

export const adminVideoFind = (id) => dispatch => {
  dispatch({ type: ADMIN_VIDEO_FIND_REQUEST })
  axios.get(`admin/videos/get/${id}`)
    .then(({ data }) => dispatch({ type: ADMIN_VIDEO_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_VIDEO_FIND_FAILURE, payload: message }))
}

export const adminVideoDelete = (id) => dispatch => {
  dispatch({ type: ADMIN_VIDEO_ACTION_REQUEST })
  axios.post(`admin/videos/delete`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_VIDEO_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_VIDEO_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(adminVideoGet())
      dispatch(adminVideoFind(id))
    })
}
