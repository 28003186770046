import {
  Button,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"

import { adminMPesaFind } from "../../store/admin/Mpesa/adminMpesaActions"
import { Pre } from "../../components/dev/pre"
import moment from "moment"
import { permissionFind } from "../../store/admin/permissions/permissionActions"


export default function RoleFormModal({ isOpen, toggle }) {
  const [name, setName] = useState("")

  useEffect(() => setName(""), [isOpen])

  return <Modal
    isOpen={isOpen}
    toggle={toggle}
  >
    <>
      <ModalHeader>
        <h5
          className="modal-title mt-0"
          id="exampleModalFullscreenLabel"
        >
          Create Role
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>


      <ModalBody>
        <div>

          <FormGroup className="form-floating mb-3">

            <Input
              type="text"
              className="form-control"
              id="basicpill-firstname-input12"
              placeholder="Module"
              value={name}
              onChange={(val) => setName(val.target.value)} />
            <Label htmlFor="basicpill-firstname-input12">
              Module
            </Label>
          </FormGroup>


        </div>

      </ModalBody>
      <ModalFooter>
        <Button outline color="dark" onClick={() => toggle()}>Close</Button>
        <Button outline color="primary" onClick={() => toggle(name)}>Submit</Button>
      </ModalFooter>

    </>
  </Modal>

}
