import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, Navigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import AuthMiddleware from "./routes/route"
import axios from "axios"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

const token = "61|ZmHmvbLrVYozZNyLqpBeyDTNh5hs5Q4vHel26VUh"

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.post.Accept = "application/json"
axios.defaults.headers.get.Accept = "application/json"

axios.defaults.headers.common = { "Authorization": `Bearer ${token}` }


// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { BASE_URL } from "./common/config"
import { authInit } from "./store/authentication/authActions"
import Pages404 from "./pages/Utility/pages-404"

// Activating fake backend
//fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);


const getLayout = (layoutType) => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType
  }))
  const { Authentication } = useSelector(state => state)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authInit())

  }, [])

  const Layout = getLayout(layoutType)

  return (
    <React.Fragment>
      <Routes>
        {Authentication.loggedIn === false && publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {Authentication.loggedIn === true && authProtectedRoutes.map((route, idx) => (
          <>

            <Route
            path={route.path}

            element={
              <AuthMiddleware>
                <Layout>{route.component}</Layout>
              </AuthMiddleware>}
            key={idx}
            exact={true}
          />
          </>
        ))}
        <Route path="*" element={<Pages404 />} />


      </Routes>

    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App
