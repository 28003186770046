import React, { useEffect, useState } from "react"
import { Form, Input, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import axios from "axios"
import UploadComponent from "../../../components/app/upload"
import { UploadVideo } from "../../../components/app/uploadVideo"


export default class LessonFormCanvas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      form: false,
      fields: false
    }
  }

  get = () => {
    const { lessonId, courseId } = this.props
    axios.get(`app/lesson/form/${lessonId}/${courseId}`)
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }
  componentDidMount = () => this.get()

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.lessonId !== this.props.lessonId) this.get();
  }

  setFields = (element, value) => {
    let { fields } = this.state
    fields[element] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, callBack } = this.props
    const { form, fields } = this.state

    return <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>
        Lesson Form : Create
      </OffcanvasHeader>
      <OffcanvasBody>
        {form && <Form onSubmit={(e) => {
          e.preventDefault()
          callBack(form.fields)
        }}>
          <div className="form-floating mb-3">
            <select
              value={fields.value}
              onChange={e => this.setFields("course", e.target.value)}
              className="form-select">
              <option>Select</option>
              {form.categories.map(row => <option
                selected={row.id === fields.course}
                key={`category-${row.id}`}
                value={row.id}>{row.title}</option>)}
            </select>
            <label htmlFor="floatingSelectGrid">Course</label>
          </div>

          <div className="form-floating mb-3">
            <Input type="text"
                   value={fields.title}
                   onChange={e => this.setFields("title", e.target.value)}
                   className="form-control"
                   id="floatingnameInput" placeholder="Title" />

            <label htmlFor="floatingnameInput">Title</label>
          </div>
          <div className="form-floating mb-3">
            <Input type="textarea"
                   value={fields.about}
              // value={fields.description}
                   onChange={e => this.setFields("about", e.target.value)}
                   className="form-control" rows={5} id="float-description" />
            <label htmlFor="float-description">About</label>
          </div>
          <div className="form-floating mb-3">
            <Input type="textarea"
                   value={fields.resources}
              // value={fields.description}
                   onChange={e => this.setFields("resources", e.target.value)}
                   className="form-control" rows={5} id="float-outline" />
            <label htmlFor="float-outline">Resources</label>
          </div>
          <UploadVideo updateVideo={data => this.setFields("video", data)} video={fields.video} />
          <div>
            <button type="submit" className="btn btn-primary w-md">Submit</button>
          </div>
        </Form>}
      </OffcanvasBody>
    </Offcanvas>
  }
}

