import axios from "axios"
import {
  SUBSCRIPTION_GET_REQUEST,
  SUBSCRIPTION_GET_SUCCESS,
  SUBSCRIPTION_GET_FAILURE,
  SUBSCRIPTION_FIND_REQUEST,
  SUBSCRIPTION_FIND_SUCCESS,
  SUBSCRIPTION_FIND_FAILURE,
  SUBSCRIPTION_ACTION_REQUEST,
  SUBSCRIPTION_ACTION_SUCCESS,
  SUBSCRIPTION_ACTION_FAILURE
} from "./subscriptionTypes"

export const subscriptionGet = () => dispatch => {
  dispatch({ type: SUBSCRIPTION_GET_REQUEST })
  axios.get(`app/subscription/get`)
    .then(({ data }) => dispatch({ type: SUBSCRIPTION_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SUBSCRIPTION_GET_FAILURE, payload: message }))
}

export const subscriptionFind = (id) => dispatch => {
  dispatch({ type: SUBSCRIPTION_FIND_REQUEST })
  axios.get(`app/subscription/get/${id}`)
    .then(({ data }) => dispatch({ type: SUBSCRIPTION_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SUBSCRIPTION_FIND_FAILURE, payload: message }))
}

export const subscriptionDelete = (id) => dispatch => {
  dispatch({ type: SUBSCRIPTION_ACTION_REQUEST })
  axios.post(`app/subscription/delete`, { id })
    .then(({ data }) => dispatch({ type: SUBSCRIPTION_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SUBSCRIPTION_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(subscriptionGet())
      dispatch(subscriptionFind(id))
    })
}

export const subscriptionResolve = (id) => dispatch => {
  dispatch({ type: SUBSCRIPTION_ACTION_REQUEST })
  axios.post(`app/subscription/resolve`, { id })
    .then(({ data }) => dispatch({ type: SUBSCRIPTION_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SUBSCRIPTION_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(subscriptionGet())
      dispatch(subscriptionFind(id))
    })
}


