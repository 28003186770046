import { Modal } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { adminVideoFind } from "../../store/admin/videos/adminVideoActions"

import { VideoPlayer } from "../../trainer/video/playVideo"
import { humanFileSize } from "../../components/app/sizeformat"
import moment from "moment"
import { UserChip } from "../../components/app/userComponents"
import { Link } from "react-router-dom"


export const VideoDetailModal = ({ videoID, isOpen, toggle }) => {

  const [active, setActive] = useState("info")

  const { AdminVideos } = useSelector(state => state)
  const dispatch = useDispatch()

  const get = () => dispatch(adminVideoFind(videoID))

  useEffect(() => {
    if (videoID && isOpen) {
      get()
    }
  }, [isOpen, videoID])

  const { video, loading } = AdminVideos


  return <Modal
    isOpen={isOpen}
    toggle={toggle}
  >
    <>
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          id="exampleModalFullscreenLabel"
        >
          {loading ? "Loading" : video && video.info.title}
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {!loading && video &&
      <div className="modal-body">
        <VideoPlayer video={video.info.video} playsInline={true} />
        <table className="table ">
          <tbody>
          <tr>
            <th>Uploaded By</th>
            <td><UserChip plain {...video.user} /></td>
          </tr>
          {video.course && <tr>
            <th>Course</th>
            <td><Link to={`/admin/course/${video.course.id}`}><strong>{video.course.title}</strong></Link></td>
          </tr>}
          {video.lesson && <tr>
            <th>Lesson</th>
            <td><Link to={`/admin/lessons/${video.lesson.id}`}><strong>{video.lesson.title}</strong></Link></td>
          </tr>}
          <tr>
            <th>Duration</th>
            <td>{video.info && video.info.duration}</td>
          </tr>
          <tr>
            <th>Filesize</th>
            <td>{video.upload && humanFileSize(video.upload.filesize)}</td>
          </tr>
          <tr>
            <th>Upload Date</th>
            <td>{video.upload && moment(video.upload.created_at).format("Do MMM YYYY HH:mm")}</td>
          </tr>
          </tbody>
        </table>
      </div>
      }

    </>
  </Modal>
}
