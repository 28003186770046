import axios from "axios"

import {
  AUTHENTICATION_LOGIN_REQUEST,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGIN_FAILURE,
  AUTHENTICATION_GET_REQUEST,
  AUTHENTICATION_GET_SUCCESS,
  AUTHENTICATION_GET_FAILURE,
  AUTHENTICATION_GOOGLE_REQUEST,
  AUTHENTICATION_GOOGLE_SUCCESS,
  AUTHENTICATION_GOOGLE_FAILURE,
  AUTHENTICATION_FACEBOOK_REQUEST,
  AUTHENTICATION_FACEBOOK_SUCCESS,
  AUTHENTICATION_FACEBOOK_FAILURE,
  AUTHENTICATION_LOGOUT_REQUEST,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_LOGOUT_FAILURE
} from "./authTypes"


export const login = ({ email, password, remember }) => dispatch => {
  dispatch({ type: AUTHENTICATION_LOGIN_REQUEST })
  axios.post(`auth/login`, { email, password, remember })
    .then(({ data }) => {
      dispatch({ type: AUTHENTICATION_LOGIN_SUCCESS, payload: data })
      axios.defaults.headers.common = { "Authorization": `Bearer ${data.token}` }
      dispatch(authSet(data.token))
    }).catch(({ message }) => dispatch({ type: AUTHENTICATION_LOGIN_FAILURE, payload: message }))
}

export const authInit = () => dispatch => {
  let authString = localStorage.getItem("auth")
  if (authString) {
    const auth = JSON.parse(authString)
    dispatch({ type: AUTHENTICATION_LOGIN_SUCCESS, payload: auth.token })
    dispatch({ type: AUTHENTICATION_GET_SUCCESS, payload: auth.user })
  } else {
    console.log("INIT FAILED")

  }

}

export const authSet = (token) => dispatch => {

  dispatch({ type: AUTHENTICATION_GET_REQUEST })
  axios.get(`user`, { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      console.log(data)
      dispatch({ type: AUTHENTICATION_GET_SUCCESS, payload: data })
      localStorage.setItem("auth", JSON.stringify({ token, user: data }))
    })
    .catch(({ message }) => dispatch({ type: AUTHENTICATION_GET_FAILURE, payload: message }))
}

export const logout = () => dispatch => {
  // return console.log("Logout")
  localStorage.removeItem("auth")
  axios.defaults.headers.common = { "Authorization": `Bearer ` }
  axios.get(`logout`)
    .then(() => dispatch({ type: AUTHENTICATION_LOGOUT_SUCCESS }))
    .catch(() => dispatch({ type: AUTHENTICATION_LOGOUT_FAILURE }))
    .finally(() => dispatch({ type: AUTHENTICATION_LOGOUT_SUCCESS })
    )

}

export const userGet = () => dispatch => {
  dispatch({ type: AUTHENTICATION_GET_REQUEST })
  axios.get(`user`)
    .then(({ data }) => dispatch({ type: AUTHENTICATION_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: AUTHENTICATION_GET_FAILURE, payload: message }))
}
