import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody, CardSubtitle,
  CardTitle, Col, FormGroup, Input, InputGroup,
  Nav,
  NavItem,
  NavLink, Row,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap"
import classnames from "classnames"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"

import { roleFind, roleRemoveUser } from "../../store/admin/roles/roleActions"
import { Image } from "../../components/app/image"
import { permissionAssignRole } from "../../store/admin/permissions/permissionActions"


export const RoleDetails = () => {
  const dispatch = useDispatch()
  const { Roles } = useSelector(state => state)
  const { loading, role } = Roles
  const { id } = useParams()
  const [active, setActive] = useState("users")
  const [search, setSearch] = useState("")

  const get = () => id && dispatch(roleFind(id))
  useEffect(get, [id])

  const columns = [
    {
      selector: ({ id }) => id,
      wrap: true,
      name: "",
      width: "70px",
      sortable: false,
      cell: ({ avatar }) => <Image src={avatar} className="img-fluid rounded-1 shadow-sm" />
    },
    {
      selector: ({ name }) => name, name: "Name", sortable: true, cell: ({ name, email }) => <div>
        <div><strong>{name}</strong></div>
        <div><span>{email}</span></div>
      </div>
    },
    {
      cell: ({ roles }, index) =>
        <div className="">
          {roles.map(({ name, id }) => <Badge
            key={`role-${index}-${id}`}
            color={name === "admin" ? "danger" : "info"}

            className="inline me-2"
          >{name.toUpperCase()}</Badge>)}
        </div>
    },
    {
      selector: ({ id }) => id,
      sortable: false,
      name: "Remove",
      right: true,
      cell: ({ id }) => <Link tag="a" onClick={() => dispatch(roleRemoveUser(id, role.info.id))}>
        <i className="mdi mdi-account-minus-outline font-size-20 text-danger "></i>
      </Link>
    }
  ]

  return role && <Card>
    <CardBody className="bg-white">
      <Button className="float-end" size="sm" outline color={"primary"} onClick={get}>
        {loading && <Spinner size="sm" />}
        {!loading && <i className="mbi mdi mdi-reload"></i>}
        {" "} Reload
      </Button>
      <CardTitle>{role && role.info.name.toUpperCase()}</CardTitle>
    </CardBody>

    <CardBody>
      <FormGroup className="input-group inline float-end">
        <InputGroup>
          <Input placeholder={`Search Role ${role && role.info.name}...`}
                 value={search} onChange={e => setSearch(e.target.value)} />
          <div className="input-group-text">@</div>

        </InputGroup>

      </FormGroup>
    </CardBody>

    <Nav tabs className="nav-tabs-custom nav-justified">
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({ active: active === "users" })}
          onClick={() => setActive("users")}
        >
                        <span className="d-block ">
                          <i className="font-size-20 mdi mdi-account-group-outline"></i>
                        </span>

          <span className="d-none d-sm-block">Users</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({ active: active === "permissions" })}
          onClick={() => setActive("permissions")}>
                        <span className="d-block ">
                          <i className="font-size-20 mdi mdi-account-key-outline"></i>
                        </span>
          <span className="d-none d-sm-block">Permissions</span>
        </NavLink>
      </NavItem>

    </Nav>

    <TabContent
      activeTab={active}
      className="p-3 text-muted"
    >
      <TabPane tabId="users">
        <Datatable data={role.users.filter(row => JSON.stringify(row).toLowerCase().includes(search))} columns={columns} pagination />
      </TabPane>
      <TabPane tabId="permissions">
        {role && role.permission_modules?.map(module => <div key={`permission-module-${module}`}>
          <CardTitle className="mb-2">{module}</CardTitle>
          <Row>
            {role && role.permission_types?.map(type => <Col key={`permission-type-module-${module}`}>
              <CardSubtitle className="mb-3 "><i className="mdi mdi-arrow-right" /> {type.toUpperCase()}</CardSubtitle>

              {role.permissions.filter(row => row.type === type && row.module === module)
                .map(({ id, name, checked }) => <div key={`permission-type-${type}-${module}-${id}`}
                                                     className="form-check form-switch mb-3"

                >
                  <input
                    type="checkbox"
                    className="form-check-input   me-2"
                    checked={checked}
                    value={id}
                    onClick={() => dispatch(permissionAssignRole(role.info.id, id))}
                    id={`input-permission-type-${type}-${module}-${id}`}
                  />
                  <label
                    className="form-check-label me-2"
                    htmlFor={`input-permission-type-${type}-${module}-${id}`}
                  >
                    {name.toUpperCase()}
                  </label>

                </div>)}

            </Col>)}
          </Row>
          <hr className="border-dark border-1 border-opacity-50" />

        </div>)}


      </TabPane>

    </TabContent>

  </Card>

}
