import React, { useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../common/config"


export const Image = (props, { src }) => {
  const [uri, setUri] = useState(src)

  return <img src={uri} {...props}
              onError={() => {
                setUri(BASE_URL + "../blank.jpg")
                console.log(src)
              }
              } />
}
