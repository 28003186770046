export const ADMIN_COURSE_GET_REQUEST = "ADMIN_COURSE_GET_REQUEST"
export const ADMIN_COURSE_GET_SUCCESS = "ADMIN_COURSE_GET_SUCCESS"
export const ADMIN_COURSE_GET_FAILURE = "ADMIN_COURSE_GET_FAILURE"

export const ADMIN_COURSE_FIND_REQUEST = "ADMIN_COURSE_FIND_REQUEST"
export const ADMIN_COURSE_FIND_SUCCESS = "ADMIN_COURSE_FIND_SUCCESS"
export const ADMIN_COURSE_FIND_FAILURE = "ADMIN_COURSE_FIND_FAILURE"

export const ADMIN_COURSE_ACTION_REQUEST = "ADMIN_COURSE_ACTION_REQUEST"
export const ADMIN_COURSE_ACTION_SUCCESS = "ADMIN_COURSE_ACTION_SUCCESS"
export const ADMIN_COURSE_ACTION_FAILURE = "ADMIN_COURSE_ACTION_FAILURE"
