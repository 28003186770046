export const ADMIN_ROLES_GET_REQUEST = 'ADMIN_ROLES_GET_REQUEST';
export const ADMIN_ROLES_GET_SUCCESS = 'ADMIN_ROLES_GET_SUCCESS';
export const ADMIN_ROLES_GET_FAILURE = 'ADMIN_ROLES_GET_FAILURE';

export const ADMIN_ROLES_FIND_REQUEST = 'ADMIN_ROLES_FIND_REQUEST';
export const ADMIN_ROLES_FIND_SUCCESS = 'ADMIN_ROLES_FIND_SUCCESS';
export const ADMIN_ROLES_FIND_FAILURE = 'ADMIN_ROLES_FIND_FAILURE';

export const ADMIN_ROLES_ACTION_REQUEST = 'ADMIN_ROLES_ACTION_REQUEST';
export const ADMIN_ROLES_ACTION_SUCCESS = 'ADMIN_ROLES_ACTION_SUCCESS';
export const ADMIN_ROLES_ACTION_FAILURE = 'ADMIN_ROLES_ACTION_FAILURE';

