import axios from 'axios';
import { BIN_GET_REQUEST,
  BIN_GET_SUCCESS,
  BIN_GET_FAILURE,
  BIN_FIND_REQUEST,
  BIN_FIND_SUCCESS,
  BIN_FIND_FAILURE,
  BIN_DELETE_REQUEST,
  BIN_DELETE_SUCCESS,
  BIN_DELETE_FAILURE,
  BIN_RESTORE_REQUEST,
  BIN_RESTORE_SUCCESS,
  BIN_RESTORE_FAILURE, } from "./binTypes"




export const binGet = () => dispatch => {
  dispatch({type: BIN_GET_REQUEST})
  axios.get(`app/archive/get`)
    .then(res => dispatch({type: BIN_GET_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: BIN_GET_FAILURE, payload: res.message}))
}


export const binFind = (id) => dispatch => {
  dispatch({type: BIN_FIND_REQUEST})
  axios.get(`app/archive/get/${id}`)
    .then(res => dispatch({type: BIN_FIND_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: BIN_FIND_FAILURE, payload: res.message}))
}

export const binDelete = (id) => dispatch => {
  dispatch({type: BIN_DELETE_REQUEST})
  axios.post(`app/archive/get`, {id})
    .then(res => dispatch({type: BIN_DELETE_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: BIN_DELETE_FAILURE, payload: res.message}))
    .finally(() => dispatch(binGet()))
}

export const binRestore = (id) => dispatch => {
  dispatch({type: BIN_RESTORE_REQUEST})
  axios.post(`app/archive/get`, {id})
    .then(res => dispatch({type: BIN_RESTORE_SUCCESS, payload: res.data}))
    .catch(res => dispatch({type: BIN_RESTORE_FAILURE, payload: res.message}))
    .finally(() => dispatch(binGet()))
}
