import axios from "axios"
import {
  USER_GET_REQUEST, USER_GET_SUCCESS, USER_GET_FAILURE,
  USER_FIND_REQUEST, USER_FIND_SUCCESS, USER_FIND_FAILURE,
  USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAILURE,
  USER_ACTION_REQUEST, USER_ACTION_SUCCESS, USER_ACTION_FAILURE

} from "./userTypes"


export const userGet = () => dispatch => {
  dispatch({ type: USER_GET_REQUEST })
  axios.get(`/admin/users/get`)
    .then(({ data }) => dispatch({ type: USER_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_GET_FAILURE, payload: message }))
}


export const userFind = id => dispatch => {
  dispatch({ type: USER_FIND_REQUEST })
  axios.get(`/admin/users/get/${id}`)
    .then(({ data }) => dispatch({ type: USER_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_FIND_FAILURE, payload: message }))
}

export const userBlock = id => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/users/block`, { id })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}
export const userUnblock = id => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/users/unblock`, { id })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}

export const userMakeTrainer = id => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/roles/trainer`, { id })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}

export const userMakeAdmin = id => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/roles/admin`, { id })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}

export const userRemoveAdmin = id => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/roles/admin`, { id })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}


export const userRemoveTrainer = id => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/roles/trainer`, { id })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}


export const userDelete = id => dispatch => {
  dispatch({ type: USER_DELETE_REQUEST })
  axios.post(`/admin/users/delete`, { id })
    .then(({ data }) => dispatch({ type: USER_DELETE_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_DELETE_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
    })
}

export const userRolesSubmit = (roles, user) => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/admin/roles/user/submit`, { roles, user })
    .then(({ data }) => dispatch({ type: USER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(userGet())
      // dispatch(userFind(id))
    })
}
