import {
  Button,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"

import { adminMPesaFind } from "../../store/admin/Mpesa/adminMpesaActions"
import { Pre } from "../../components/dev/pre"
import moment from "moment"
import { permissionFind } from "../../store/admin/permissions/permissionActions"


export default class PermissionFormModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: false,
      form: false,
      loading: false,
      roles: []
    }
  }

  get = () => {
    const { isOpen, permission, toggle } = this.props
    if (isOpen === false) toggle()


    this.setState({ loading: true })
    axios.get(`/admin/permissions/form/${permission}`)
      .then(res => {
        console.log(res.data)

        this.setState({ form: res.data, fields: res.data.fields, roles: res.data.roles })
      })
      .catch(() => toggle())
      .finally(() => this.setState({ loading: false }))
  }

  componentDidMount() {
    this.get()
  }


  onChange = (field, value) => {
    let { fields } = this.state
    fields[field] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle } = this.props
    const { loading,roles, form, fields } = this.state
    return <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <>
        <ModalHeader>
          <h5
            className="modal-title mt-0"
            id="exampleModalFullscreenLabel"
          >
            {form && fields.name} {loading && "loading"}
          </h5>
          <button
            onClick={toggle}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>


        <ModalBody>
          {!loading && form && <div>

            <FormGroup className="form-floating mb-3">

              <Input
                type="text"
                className="form-control"
                id="basicpill-firstname-input12"
                placeholder="Module"
                value={fields.module}
                onChange={(val) => this.onChange("module", val.target.value)} />
              <Label htmlFor="basicpill-firstname-input12">
                Module
              </Label>
            </FormGroup>
            <FormGroup className="form-floating mb-3">

              <select
                type="text"
                className="form-control"
                id="basicpill-firstname-input12"
                value={fields.type}
                onChange={(val) => this.onChange("type", val.target.value)}>

                {form && form.enums.type.map((row,index) => <option key={`permissions-${index}-${row}`} selected={row === fields.type} value={row} >{row.toUpperCase()}</option>)}

              </select>
              <Label htmlFor="basicpill-firstname-input12">
                Module
              </Label>
            </FormGroup>

            <CardTitle>Roles</CardTitle>
            {roles.map(row =>  <div
              key={`role-${fields.id}-${row.id}`}
              className="form-check form-switch mb-3"
            >
              {/*<input*/}
              {/*  type="checkbox"*/}
              {/*  className="form-check-input"*/}
              {/*  defaultChecked={row.checked}*/}
              {/*  // onChange={}*/}
              {/*  id={`input-${fields.id}-${row.id}`}*/}
              {/*/>*/}
              <label
                className="form-check-label"
                htmlFor={`input-${fields.id}-${row.id}`}

              >
                {row.name.toUpperCase()}
              </label>
            </div>)}

          </div>
          }
        </ModalBody>
        {form && <ModalFooter>
          <Button outline color="dark" onClick={() => toggle()}>Close</Button>
          <Button outline color="primary" onClick={() => toggle(fields)}>Submit</Button>
        </ModalFooter>}

      </>
    </Modal>
  }
}
