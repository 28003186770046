import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, FormGroup, Input, Spinner
} from "reactstrap"
import Datatable from "react-data-table-component"
import { useDispatch, useSelector } from "react-redux"


import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Pre } from "../../components/dev/pre"
import { permissionGet, permissionUpdate } from "../../store/admin/permissions/permissionActions"
import { Link } from "react-router-dom"
import PermissionFormModal from "./permissionFormModal"

const Permissions = () => {
  const dispatch = useDispatch()
  const { Permissions } = useSelector(state => state)
  const { loading, permissions } = Permissions
  const get = () => dispatch(permissionGet())
  const [permission, setPermission] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState("")
  useEffect(get, [])

  useEffect(() => {
    permission && setIsOpen(permission !== false)
  }, [permission])


  //meta title
  document.title = "App Skill | Settings | Permissions"


  const columns = [
    {
      selector: ({ name }) => name, name: "Name", sortable: true, cell: ({ name }) => <div>
        <div><strong>{name}</strong></div>
      </div>
    },

    {
      selector: ({ module }) => module,
      name: "Module",
      style: { textTransform: "" },
      sortable: true
    },
    {
      selector: ({ type }) => type,
      name: "Type",
      style: { textTransform: "capitalize" },
      sortable: true
    },
    {
      selector: ({ roles }) => roles,
      name: "Roles",
      sortable: true,
      cell: ({ roles }, index) => roles.map(({ name, id }) =>
        <Badge color={name === "admin" ? "danger" : "info"}
               className="me-2"
               key={`role-${index}-${id}`}>{name.toUpperCase()}</Badge>)
    },
    {
      selector: ({ id }) => id,
      sortable: false,
      name: "Edit",
      style: { textTransform: "capitalize" },
      right: true,
      cell: ({ id }) => <Link tag="a" onClick={() => setPermission(id)}>
        <i className="mdi mdi-pencil "></i>
      </Link>
    }


  ]


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Permissons" />

          <Card>
            <CardBody>
              <FormGroup className="input-group inline float-end">
                <Input placeholder="Search Permissions..." value={search} onChange={e => setSearch(e.target.value)} />
                <Button className="" size="sm" outline color={"primary"} onClick={get}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <i className="mbi mdi mdi-reload"></i>}
                  {" "} Reload
                </Button>
              </FormGroup>
            </CardBody>
            <Datatable
              pagination
              data={permissions.filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()))}
              columns={columns} />
          </Card>
          {isOpen && <PermissionFormModal
            isOpen={isOpen}
            toggle={(data) => {
              setIsOpen(false)
              setPermission(false)
              data && dispatch(permissionUpdate(data))
            }} permission={permission} />}

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Permissions
