export const AUTHENTICATION_LOGIN_REQUEST =  'AUTHENTICATION_LOGIN_REQUEST';
export const AUTHENTICATION_LOGIN_SUCCESS =  'AUTHENTICATION_LOGIN_SUCCESS';
export const AUTHENTICATION_LOGIN_FAILURE =  'AUTHENTICATION_LOGIN_FAILURE';

export const AUTHENTICATION_GET_REQUEST =  'AUTHENTICATION_GET_REQUEST';
export const AUTHENTICATION_GET_SUCCESS =  'AUTHENTICATION_GET_SUCCESS';
export const AUTHENTICATION_GET_FAILURE =  'AUTHENTICATION_GET_FAILURE';

export const AUTHENTICATION_GOOGLE_REQUEST =  'AUTHENTICATION_GOOGLE_REQUEST';
export const AUTHENTICATION_GOOGLE_SUCCESS =  'AUTHENTICATION_GOOGLE_SUCCESS';
export const AUTHENTICATION_GOOGLE_FAILURE =  'AUTHENTICATION_GOOGLE_FAILURE';

export const AUTHENTICATION_FACEBOOK_REQUEST =  'AUTHENTICATION_FACEBOOK_REQUEST';
export const AUTHENTICATION_FACEBOOK_SUCCESS =  'AUTHENTICATION_FACEBOOK_SUCCESS';
export const AUTHENTICATION_FACEBOOK_FAILURE =  'AUTHENTICATION_FACEBOOK_FAILURE';

export const AUTHENTICATION_LOGOUT_REQUEST =  'AUTHENTICATION_LOGOUT_REQUEST';
export const AUTHENTICATION_LOGOUT_SUCCESS =  'AUTHENTICATION_LOGOUT_SUCCESS';
export const AUTHENTICATION_LOGOUT_FAILURE =  'AUTHENTICATION_LOGOUT_FAILURE';

