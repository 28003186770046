import {

  BIN_GET_REQUEST,
  BIN_GET_SUCCESS,
  BIN_GET_FAILURE,
  BIN_FIND_REQUEST,
  BIN_FIND_SUCCESS,
  BIN_FIND_FAILURE,
  BIN_DELETE_REQUEST,
  BIN_DELETE_SUCCESS,
  BIN_DELETE_FAILURE,
  BIN_RESTORE_REQUEST,
  BIN_RESTORE_SUCCESS,
  BIN_RESTORE_FAILURE
} from "./binTypes"

const INIT_STATE = {
  bins: [],
  bin: false,
  error: false,
  loading: false
}


const Bins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BIN_GET_REQUEST: {
      return { ...state, loading: true, error: false }
    }
    case BIN_GET_SUCCESS: {
      return { ...state, loading: false, error: false, bins: action.payload }
    }
    case BIN_GET_FAILURE: {
      return { ...state, loading: false, error: action.payload }
    }

    case BIN_FIND_REQUEST: {
      return { ...state, loading: true, error: false }
    }
    case BIN_FIND_SUCCESS: {
      return { ...state, loading: false, error: false, bin: action.payload }
    }
    case BIN_FIND_FAILURE: {
      return { ...state, loading: false, error: action.payload }
    }

    case BIN_DELETE_REQUEST: {
      return { ...state, loading: true, error: false }
    }
    case BIN_DELETE_SUCCESS: {
      return { ...state, loading: false, error: false }
    }
    case BIN_DELETE_FAILURE: {
      return { ...state, loading: false, error: action.payload }
    }

    case BIN_RESTORE_REQUEST: {
      return { ...state, loading: true, error: false }
    }
    case BIN_RESTORE_SUCCESS: {
      return { ...state, loading: false, error: false }
    }
    case BIN_RESTORE_FAILURE: {
      return { ...state, loading: false, error: action.payload }
    }


    default :
      return { ...state }
  }

}

export default Bins
