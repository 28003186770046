import {
  USER_GET_REQUEST,USER_GET_SUCCESS,USER_GET_FAILURE,
  USER_FIND_REQUEST,USER_FIND_SUCCESS,USER_FIND_FAILURE,
  USER_DELETE_REQUEST,USER_DELETE_SUCCESS,USER_DELETE_FAILURE,
  USER_ACTION_REQUEST,USER_ACTION_SUCCESS,USER_ACTION_FAILURE,
} from "./userTypes"


const INIT_STATE = {
  users: [],
  user: false,
  loading: false,
  error: false
}


const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_GET_REQUEST : return {...state, loading: true, error: '', }
    case USER_GET_SUCCESS : return {...state, loading: false, error:'', users: action.payload}
    case USER_GET_FAILURE : return {...state, loading: false, error: action.payload}

    case USER_FIND_REQUEST : return {...state, loading: true, error: '', }
    case USER_FIND_SUCCESS : return {...state, loading: false, error:'', user: action.payload}
    case USER_FIND_FAILURE : return {...state, loading: false, error: action.payload}

    case USER_ACTION_REQUEST : return {...state, loading: true, error: '', }
    case USER_ACTION_SUCCESS : return {...state, loading: false, error:''}
    case USER_ACTION_FAILURE : return {...state, loading: false, error: action.payload}

    case USER_ACTION_REQUEST : return {...state, loading: true, error: '', }
    case USER_ACTION_SUCCESS : return {...state, loading: false, error:''}
    case USER_ACTION_FAILURE : return {...state, loading: false, error: action.payload}

    case USER_DELETE_REQUEST : return {...state, loading: true, error: '', }
    case USER_DELETE_SUCCESS : return {...state, loading: false, error:''}
    case USER_DELETE_FAILURE : return {...state, loading: false, error: action.payload}


    case USER_ACTION_REQUEST : return {...state, loading: true, error: '', }
    case USER_ACTION_SUCCESS : return {...state, loading: false, error:''}
    case USER_ACTION_FAILURE : return {...state, loading: false, error: action.payload}



    default :return  {...state}
  }

}

export default Users;
