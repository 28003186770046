import {ADMIN_ROLES_GET_REQUEST,
  ADMIN_ROLES_GET_SUCCESS,
  ADMIN_ROLES_GET_FAILURE,
  ADMIN_ROLES_FIND_REQUEST,
  ADMIN_ROLES_FIND_SUCCESS,
  ADMIN_ROLES_FIND_FAILURE,
  ADMIN_ROLES_ACTION_REQUEST,
  ADMIN_ROLES_ACTION_SUCCESS,
  ADMIN_ROLES_ACTION_FAILURE} from './roleTypes';

const INIT = {
  roles: [],
  role: false,
  loading: false,
  error: ''
}

const Roles = (state = INIT, action) => {

  switch (action.type){
    case ADMIN_ROLES_GET_REQUEST: return {...state, loading: true, error: '', }
    case ADMIN_ROLES_GET_SUCCESS: return {...state, loading: false, error: '', roles: action.payload}
    case ADMIN_ROLES_GET_FAILURE: return {...state, loading: false, error: action.payload}

    case ADMIN_ROLES_FIND_REQUEST: return {...state, loading: true, error: '', }
    case ADMIN_ROLES_FIND_SUCCESS: return {...state, loading: false, error: '', role: action.payload}
    case ADMIN_ROLES_FIND_FAILURE: return {...state, loading: false, error: action.payload}

    case ADMIN_ROLES_ACTION_REQUEST: return {...state, loading: true, error: ''}
    case ADMIN_ROLES_ACTION_SUCCESS: return {...state, loading: false, error: ''}
    case ADMIN_ROLES_ACTION_FAILURE: return {...state, loading: false, error: action.payload}

    default : return  {...state}
  }


}


export default Roles
