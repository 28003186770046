
import {AUTHENTICATION_LOGIN_REQUEST,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGIN_FAILURE,
  AUTHENTICATION_GET_REQUEST,
  AUTHENTICATION_GET_SUCCESS,
  AUTHENTICATION_GET_FAILURE,
  AUTHENTICATION_GOOGLE_REQUEST,
  AUTHENTICATION_GOOGLE_SUCCESS,
  AUTHENTICATION_GOOGLE_FAILURE,
  AUTHENTICATION_FACEBOOK_REQUEST,
  AUTHENTICATION_FACEBOOK_SUCCESS,
  AUTHENTICATION_FACEBOOK_FAILURE,
  AUTHENTICATION_LOGOUT_REQUEST,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_LOGOUT_FAILURE
} from './authTypes'


const INIT_STATE = {
  loading: false,
  loggedIn: false,
  token: null,
  user: false,
  error: false
}

const Authentication = (state = INIT_STATE, action) => {
  switch (action.type){
    case AUTHENTICATION_LOGIN_REQUEST : return {...state, loading: true, error: false}
    case AUTHENTICATION_LOGIN_SUCCESS : return {...state, loading: false, error: false, loggedIn: true, token: action.payload}
    case AUTHENTICATION_LOGIN_FAILURE : return {...state, loading: false, loggedIn: false, error:  action.payload}

    case AUTHENTICATION_GET_REQUEST : return {...state, loading: true, error: false}
    case AUTHENTICATION_GET_SUCCESS : return {...state, loading: false, error: false, user: action.payload}
    case AUTHENTICATION_GET_FAILURE : return {...state, loading: false, error:  action.payload}

    case AUTHENTICATION_GOOGLE_REQUEST : return {...state, loading: true, error: false}
    case AUTHENTICATION_GOOGLE_SUCCESS : return {...state, loading: false, loggedIn: true, error: false, token: action.payload}
    case AUTHENTICATION_GOOGLE_FAILURE : return {...state, loading: false, loggedIn: false, error:  action.payload}

    case AUTHENTICATION_FACEBOOK_REQUEST : return {...state, loading: true, error: false}
    case AUTHENTICATION_FACEBOOK_SUCCESS : return {...state, loading: false, loggedIn: true, error: false, token: action.payload}
    case AUTHENTICATION_FACEBOOK_FAILURE : return {...state, loading: false, loggedIn: false, error:  action.payload}

    case AUTHENTICATION_LOGOUT_REQUEST : return {...state, loading: true, loggedIn: false, error: false}
    case AUTHENTICATION_LOGOUT_SUCCESS : return {...state, loading: false, loggedIn: false, error: false, token: null}
    case AUTHENTICATION_LOGOUT_FAILURE : return {...state, loading: false, loggedIn: false, error:  null}

    default : return {...state}


  }
}


export default Authentication
