import React, { useEffect } from "react"
import { Card, CardHeader, CardTitle, Container } from "reactstrap"
import DataTable from "react-data-table-component"

//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { binGet } from "../../store/bin/binActions"

const PagesStarter = () => {

  //meta title
  document.title = "Trash / Bin"


  const dispatch = useDispatch()
  const { Bins } = useSelector(state => state)

  useEffect(() => dispatch(binGet()), [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Deleted Items" />
          {/*<pre>{JSON.stringify(Bins, null, 2)}</pre>*/}

        <Card>
          <CardHeader color={'warning'}>
            <CardTitle>Deleted Items</CardTitle>
          </CardHeader>
          <DataTable
            columns={[
              { name: "Title", cell: row => row.title },
              { name: "Size", cell: row => row.data.length },
              { name: "Date", right: true, cell: row => moment(row.created_at).format("HH:mm Do MMM YYYY") }
            ]}
            data={Bins.bins}
          />

        </Card>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesStarter
