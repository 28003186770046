import {
  COURSE_DELETE_FAILURE,
  COURSE_DELETE_REQUEST, COURSE_DELETE_SUCCESS, COURSE_FIND_FAILURE, COURSE_FIND_REQUEST, COURSE_FIND_SUCCESS,
  COURSE_GET_FAILURE,
  COURSE_GET_REQUEST,
  COURSE_GET_SUCCESS, COURSE_PUBLISH_FAILURE,
  COURSE_PUBLISH_REQUEST,
  COURSE_PUBLISH_SUCCESS, COURSE_SUBMIT_FAILURE, COURSE_SUBMIT_REQUEST, COURSE_SUBMIT_SUCCESS
} from "./courseTypes"
import axios from "axios"


export const courseGet = () => dispatch => {
  dispatch({ type: COURSE_GET_REQUEST })
  axios.get("/app/course/get")
    .then(res => dispatch({ type: COURSE_GET_SUCCESS, payload: res.data }))
    .then(res => dispatch({ type: COURSE_GET_FAILURE, payload: res.message }))
}

export const courseSubmit = (data) => dispatch => {
  dispatch({ type: COURSE_SUBMIT_REQUEST })
  axios.post("/app/course/submit", data)
    .then(res => dispatch({ type: COURSE_SUBMIT_SUCCESS, payload: res.data }))
    .then(res => dispatch({ type: COURSE_SUBMIT_FAILURE, payload: res.message }))
}

export const courseFind = (id) => dispatch => {
  dispatch({ type: COURSE_FIND_REQUEST })
  axios.get(`/app/course/get/${id}`)
    .then(res => dispatch({ type: COURSE_FIND_SUCCESS, payload: res.data }))
    .then(res => dispatch({ type: COURSE_FIND_FAILURE, payload: res.message }))
}

export const coursePublish = (id) => dispatch => {
  dispatch({ type: COURSE_PUBLISH_REQUEST })
  axios.post("/app/course/publish", { id })
    .then(res => dispatch({ type: COURSE_PUBLISH_SUCCESS, payload: res.data }))
    .then(res => dispatch({ type: COURSE_PUBLISH_FAILURE, payload: res.message }))
    .finally(() => {
      dispatch(courseGet())
      dispatch(courseFind(id))
    })
}

export const courseDelete = (id) => dispatch => {
  dispatch({ type: COURSE_DELETE_REQUEST })
  axios.post("/app/course/delete", { id })
    .then(res => dispatch({ type: COURSE_DELETE_SUCCESS, payload: res.data }))
    .then(res => dispatch({ type: COURSE_DELETE_FAILURE, payload: res.message }))
    .finally(() => {
      dispatch(courseGet())
    })
}





