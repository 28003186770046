import React from "react";
import { Card, CardBody, CardTitle, Col, Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LineApexChart from "../../pages/AllCharts/apex/chartapex"

const Reports = () => {

  //meta title
  document.title="App Sill | Admin | Finance | Report";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Finance" breadcrumbItem="Reports" />


          <Card>
            <CardBody>
              <CardTitle className="mb-4">Subscriptions To Following Chart</CardTitle>
              <LineApexChart dataColors='["--bs-success", "--bs-info"]'/>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reports;
