import axios from "axios"
import {
  ADMIN_COURSE_GET_REQUEST,
  ADMIN_COURSE_GET_SUCCESS,
  ADMIN_COURSE_GET_FAILURE,
  ADMIN_COURSE_FIND_REQUEST,
  ADMIN_COURSE_FIND_SUCCESS,
  ADMIN_COURSE_FIND_FAILURE,
  ADMIN_COURSE_ACTION_REQUEST,
  ADMIN_COURSE_ACTION_SUCCESS,
  ADMIN_COURSE_ACTION_FAILURE
} from "./adminCourseTypes"


export const adminCourseGet = () => dispatch => {
  dispatch({ type: ADMIN_COURSE_GET_REQUEST })
  axios.get(`admin/course/get`)
    .then(({ data }) => dispatch({ type: ADMIN_COURSE_GET_SUCCESS, payload: data }))
    .catch(({ data }) => dispatch({ type: ADMIN_COURSE_GET_FAILURE, payload: data }))
}

export const adminCourseFind = (id) => dispatch => {
  dispatch({ type: ADMIN_COURSE_FIND_REQUEST })
  axios.get(`admin/course/get/${id}`)
    .then(({ data }) => dispatch({ type: ADMIN_COURSE_FIND_SUCCESS, payload: data }))
    .catch(({ data }) => dispatch({ type: ADMIN_COURSE_FIND_FAILURE, payload: data }))
}

export const adminCourseApprove = (id) => dispatch => {
  dispatch({ type: ADMIN_COURSE_ACTION_REQUEST })
  axios.post(`admin/course/approve`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_COURSE_ACTION_SUCCESS, payload: data }))
    .catch(({ data }) => dispatch({ type: ADMIN_COURSE_ACTION_FAILURE, payload: data }))
    .finally(() => {
      dispatch(adminCourseGet())
      dispatch(adminCourseFind(id))
    })
}

export const adminCourseReject = (id) => dispatch => {
  dispatch({ type: ADMIN_COURSE_ACTION_REQUEST })
  axios.post(`admin/course/reject`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_COURSE_ACTION_SUCCESS, payload: data }))
    .catch(({ data }) => dispatch({ type: ADMIN_COURSE_ACTION_FAILURE, payload: data }))
    .finally(() => {
      dispatch(adminCourseGet())
      dispatch(adminCourseFind(id))
    })
}

export const adminCourseDelete = (id) => dispatch => {
  dispatch({ type: ADMIN_COURSE_ACTION_REQUEST })
  axios.post(`admin/course/delete`, { id })
    .then(({ data }) => dispatch({ type: ADMIN_COURSE_ACTION_SUCCESS, payload: data }))
    .catch(({ data }) => dispatch({ type: ADMIN_COURSE_ACTION_FAILURE, payload: data }))
    .finally(() => {
      dispatch(adminCourseGet())
    })
}


