import axios from "axios"


import {
  TRAINER_GET_REQUEST,
  TRAINER_GET_SUCCESS,
  TRAINER_GET_FAILURE,
  TRAINER_FIND_REQUEST,
  TRAINER_FIND_SUCCESS,
  TRAINER_FIND_FAILURE, TRAINER_ACTION_REQUEST, TRAINER_ACTION_SUCCESS, TRAINER_ACTION_FAILURE

} from "./trainerTypes"
import { userFind, userGet } from "../users/userActions"


export const trainerGet = () => dispatch => {
  dispatch({ type: TRAINER_GET_REQUEST })
  axios.get(`/admin/trainer/get`)
    .then(({ data }) => dispatch({ type: TRAINER_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: TRAINER_GET_FAILURE, payload: message }))
}


export const trainerFind = id => dispatch => {
  dispatch({ type: TRAINER_FIND_REQUEST })
  axios.get(`/admin/trainer/get/${id}`)
    .then(({ data }) => dispatch({ type: TRAINER_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: TRAINER_FIND_FAILURE, payload: message }))

}

export const trainerBlock = id => dispatch => {
  dispatch({ type: TRAINER_ACTION_REQUEST })
  axios.post(`/admin/trainer/block`, {id})
    .then(({ data }) => dispatch({ type: TRAINER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: TRAINER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(trainerGet())
      dispatch(trainerFind(id))
    })
}


export const trainerVerify = id => dispatch => {
  dispatch({ type: TRAINER_ACTION_REQUEST })
  axios.post(`/admin/trainer/verify`, {id})
    .then(({ data }) => dispatch({ type: TRAINER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: TRAINER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(trainerGet())
      dispatch(trainerFind(id))
    })
}

export const trainerReject = id => dispatch => {
  dispatch({ type: TRAINER_ACTION_REQUEST })
  axios.post(`/admin/trainer/reject`, {id})
    .then(({ data }) => dispatch({ type: TRAINER_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: TRAINER_ACTION_FAILURE, payload: message }))
    .finally(() => {
      dispatch(trainerGet())
      dispatch(trainerFind(id))
    })
}


