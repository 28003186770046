import React, { useEffect, useState } from "react"
import { Form, Input, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import axios from "axios"
import UploadComponent from "../../../components/app/upload"
import { UploadVideo } from "../../../components/app/uploadVideo"

const CourseFormCanvas = ({ courseId, categoryId, isOpen, toggle, callBack }) => {
  const [form, setForm] = useState()
  const [upload, setUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const setField = (element, value) => {
    let formValues = form
    formValues.fields[element] = value
    setForm(formValues)
  }

  const set = () => {
    setLoading(true)
    axios.get(`app/course/form/${courseId}`)
      .then(res => setForm(res.data))
  }

  useEffect(() => {
    isOpen && set()
  }, [isOpen])

  return <Offcanvas
    isOpen={isOpen}
    direction="end"
    toggle={toggle}>
    <OffcanvasHeader toggle={toggle}>
      Course Form
    </OffcanvasHeader>
    <OffcanvasBody>
      {form && <Form onSubmit={(e) => {
        callBack(form.fields)
        e.preventDefault()
      }}>
        <div className="form-floating mb-3">
          <Input type="text"
                 defaultValue={form.fields.title}
                 onChange={e => setField("title", e.target.value)}
                 className="form-control"
                 id="floatingnameInput" placeholder="Title" />

          <label htmlFor="floatingnameInput">Title</label>
        </div>
        <div className="form-floating mb-3">
          <select
            defaultValue={form.fields.value}
            onChange={e => setField("category", e.target.value)}
            className="form-select">
            <option>Select</option>
            {form.categories.map(row => <option
              selected={row.id === form.fields.category}
              key={`category-${row.id}`}
              value={row.id}>{row.title}</option>)}

          </select>
          <label htmlFor="floatingSelectGrid">Category</label>
        </div>
        <div className="form-floating mb-3">
          <Input type="textarea"
                 defaultValue={form.fields.description}
            // value={form.fields.description}
                 onChange={e => setField("description", e.target.value)}
                 className="form-control" rows={5} id="float-description" />
          <label htmlFor="float-description">Description</label>
        </div>
        <div className="form-floating mb-3">
          <Input type="textarea"
                 defaultValue={form.fields.outline}
            // value={form.fields.description}
                 onChange={e => setField("outline", e.target.value)}
                 className="form-control" rows={5} id="float-outline" />
          <label htmlFor="float-outline">Course Outline</label>
        </div>
        <div className="form-floating mb-3">
          <Input type="textarea"
                 defaultValue={form.fields.requirements}
                 onChange={e => setField("requirements", e.target.value)}
                 className="form-control" rows={5} id="float-requirements" />
          <label htmlFor="float-requirements">Requirements</label>
        </div>

        <UploadVideo video={form.fields.video} title="Upload Course Trainer"
                     updateVideo={data => setField("video", data)} />


        <div>
          <button type="submit" className="btn btn-primary w-md">Submit</button>
        </div>
      </Form>}
    </OffcanvasBody>
  </Offcanvas>
}


export default CourseFormCanvas

