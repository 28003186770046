export const ADMIN_VIDEO_GET_REQUEST = 'ADMIN_VIDEO_GET_REQUEST';
export const ADMIN_VIDEO_GET_SUCCESS = 'ADMIN_VIDEO_GET_SUCCESS';
export const ADMIN_VIDEO_GET_FAILURE = 'ADMIN_VIDEO_GET_FAILURE';

export const ADMIN_VIDEO_FIND_REQUEST = 'ADMIN_VIDEO_FIND_REQUEST';
export const ADMIN_VIDEO_FIND_SUCCESS = 'ADMIN_VIDEO_FIND_SUCCESS';
export const ADMIN_VIDEO_FIND_FAILURE = 'ADMIN_VIDEO_FIND_FAILURE';

export const ADMIN_VIDEO_ACTION_REQUEST = 'ADMIN_VIDEO_ACTION_REQUEST';
export const ADMIN_VIDEO_ACTION_SUCCESS = 'ADMIN_VIDEO_ACTION_SUCCESS';
export const ADMIN_VIDEO_ACTION_FAILURE = 'ADMIN_VIDEO_ACTION_FAILURE';

