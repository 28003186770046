

export const LESSON_GET_REQUEST = 'LESSON_GET_REQUEST';
export const LESSON_GET_SUCCESS = 'LESSON_GET_SUCCESS';
export const LESSON_GET_FAILURE = 'LESSON_GET_FAILURE';

export const LESSON_FIND_REQUEST = 'LESSON_FIND_REQUEST';
export const LESSON_FIND_SUCCESS = 'LESSON_FIND_SUCCESS';
export const LESSON_FIND_FAILURE = 'LESSON_FIND_FAILURE';

export const LESSON_SUBMIT_REQUEST = 'LESSON_SUBMIT_REQUEST';
export const LESSON_SUBMIT_SUCCESS = 'LESSON_SUBMIT_SUCCESS';
export const LESSON_SUBMIT_FAILURE = 'LESSON_SUBMIT_FAILURE';

export const LESSON_PUBLISH_REQUEST = 'LESSON_PUBLISH_REQUEST';
export const LESSON_PUBLISH_SUCCESS = 'LESSON_PUBLISH_SUCCESS';
export const LESSON_PUBLISH_FAILURE = 'LESSON_PUBLISH_FAILURE';

export const LESSON_DEPRECATE_REQUEST = 'LESSON_DEPRECATE_REQUEST';
export const LESSON_DEPRECATE_SUCCESS = 'LESSON_DEPRECATE_SUCCESS';
export const LESSON_DEPRECATE_FAILURE = 'LESSON_DEPRECATE_FAILURE';

export const LESSON_DELETE_REQUEST = 'LESSON_DELETE_REQUEST';
export const LESSON_DELETE_SUCCESS = 'LESSON_DELETE_SUCCESS';
export const LESSON_DELETE_FAILURE = 'LESSON_DELETE_FAILURE';
