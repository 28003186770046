import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import PagesMaintenance from "../pages/Utility/pages-maintenance"

const NewPage = () => {

  //meta title
  document.title="Stater Page | Skote - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />
          <PagesMaintenance />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewPage;
