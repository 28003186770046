import {
  Button, Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { adminCourseFind } from "../../store/admin/courses/adminCourseActions"
import { CourseStatus } from "../../components/app/statusBudges"
import moment from "moment"
import { LessonsList } from "../lessons/components/lessonsList"
import { courseFind } from "../../store/courses/courseActions"
import { Link } from "react-router-dom"
import { UserChip } from "../../components/app/userComponents"
import { VideoPlayer } from "../../trainer/video/playVideo"
import { LessonDetailModal } from "../lessons/lessonDetailModal"


export const CourseDetailsModal = ({ courseID, isOpen, toggle }) => {
  const dispatch = useDispatch()
  const { AdminCourses } = useSelector(state => state)
  const get = () => dispatch(adminCourseFind(courseID))
  const [active, setActive] = useState("information")
  const [lesson, setLesson] = useState(false)
  const [modal, setModal] = useState(false)
  useEffect(() => {
    if (isOpen && courseID) get()
  }, [isOpen, courseID])

  useEffect(() => setModal(lesson !== false), [lesson, modal])

  const { course, loading } = AdminCourses


  return <Modal
    size="lg"
    isOpen={isOpen}
    toggle={toggle}
  >
    <div className="modal-header">
      <h5
        className="modal-title mt-0"
        id="exampleModalFullscreenLabel"
      >
        {loading ? "Loading" : course && course.info && course.info.title}
      </h5>
      <button
        onClick={toggle}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">

      {course && <>
        <Card>

          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                active={active === "lessons"}
                onClick={() => setActive("lessons")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                <span className="d-none d-sm-block">Lessons</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                active={active === "information"}
                onClick={() => setActive("information")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                <span className="d-none d-sm-block">Information</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                active={active === "comments"}
                onClick={() => setActive("comments")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                <span className="d-none d-sm-block">Comments</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={active}
            className="text-muted">
            <TabPane tabId="lessons">
              {course && <LessonsList
                onClick={(id) => setLesson(id) }
                callBack={() => dispatch(courseFind(id))}
                hideCategory={true}
                hideCourse={true}
                omits={["traffic", "duration", "size", "status", "actions"]}
                lessons={course.lessons}
                loading={loading} />}

              <div className="text-center mb-5">

              </div>

            </TabPane>
            <TabPane tabId="information">
              <div className="p-2">
                <Row>
                  <Col sm={6}> <Table>
                    <tbody>
                    <tr>
                      <th>TITLE</th>
                      <td>{course && course.info.title}</td>
                    </tr>
                    <tr>
                      <th>CATEGORY</th>
                      <td>{course && course.category && course.category.title}</td>
                    </tr>
                    <tr>
                      <th>TRAINER</th>
                      <td>
                        <UserChip avatar={course.trainer.avatar} name={course.trainer.name} id={course.trainer.id}
                                  plain />
                      </td>
                    </tr>
                    <tr>
                      <th>STATUS</th>
                      <td><CourseStatus status={course.info.status} /></td>
                    </tr>

                    </tbody>
                  </Table></Col>
                  <Col sm={6}>
                    <VideoPlayer video={course.video.video} />
                  </Col>
                </Row>
                <h4>Description</h4>
                {course.info.description}
                <hr />
                <h4>Outline</h4>
                {course.info.outline}
                <hr />

                <h4>Requirements</h4>
                {course.info.requirements}
                <hr />
                <hr />

              </div>
            </TabPane>
            <TabPane tabId="comments">

              <div className="m-5">
                <div>
                  {course.comments.map(row => <div
                    key={`comments-${row.id}`}
                    className="d-flex py-3">
                    <div className="avatar-xs me-3">
                      <div className="avatar-title rounded-circle bg-light text-primary">
                        <i className="bx bxs-user"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mb-1">
                        Delores Williams{" "}
                        <small className="text-muted float-end">
                          {moment(row.created_at).fromNow()}
                        </small>
                      </h5>
                      <p className="text-muted">
                        {row.message}
                      </p>
                      <div>
                        <Link to="#" className="text-success">
                          <i className="mdi mdi-reply"></i> Reply
                        </Link>
                      </div>
                    </div>
                  </div>)}

                </div>
              </div>

            </TabPane>
          </TabContent>
        </Card>
      </>}
      <LessonDetailModal toggle={() => setLesson(false)} isOpen={modal} lessonID={lesson} />

    </div>

  </Modal>
}
