import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { roleCreate, roleGet } from "../../store/admin/roles/roleActions"
import { RoleDetails } from "./roleDetails"
import RoleFormModal from "./roleFormModal"


const Roles = () => {
  const dispatch = useDispatch()
  const { Roles } = useSelector(state => state)
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()
  const { loading, roles } = Roles
  const { id } = useParams()

  const get = () => {
    dispatch(roleGet())
    // dispatch(roleFind(id))
  }


  useEffect(get, [])


  document.title = "App Skill | Admin | Roles"


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Roles" />

          <Row>
            <Col sm={4}>

              <Card>
                <CardBody>
                  <Button className="float-end" size="sm" outline color={"primary"} onClick={() => setIsOpen(!isOpen)}>
                    <i className="mbi mdi mdi-plus"></i> New
                  </Button>
                  <CardTitle>Roles</CardTitle>
                </CardBody>
                <CardBody>
                  <ListGroup>

                    {roles.map((row) => <ListGroupItem
                      active={id == row.id}
                      className={`pointer-event ${id == row.id && "active"}`}
                      tag={"a"}
                      onClick={() => navigate(`/admin/settings/roles/${row.id}`)}
                      key={`roles-${row.name}`}>

                      {row.name.toUpperCase()}

                    </ListGroupItem>)}
                  </ListGroup>

                </CardBody>
              </Card>
            </Col>
            <Col sm={8}>
              <RoleDetails />
            </Col>
          </Row>


        </Container>
        <RoleFormModal isOpen={isOpen} toggle={(name) => {
          setIsOpen(false)
          dispatch(roleCreate(name, (id) => {
            get();
            id && navigate(`/admin/settings/roles/${id}`)
          }))
        }} />
      </div>
    </React.Fragment>
  )
}

export default Roles
