export const TRAINER_GET_REQUEST = 'TRAINER_GET_REQUEST';
export const TRAINER_GET_SUCCESS = 'TRAINER_GET_SUCCESS';
export const TRAINER_GET_FAILURE = 'TRAINER_GET_FAILURE';


export const TRAINER_FIND_REQUEST = 'TRAINER_FIND_REQUEST';
export const TRAINER_FIND_SUCCESS = 'TRAINER_FIND_SUCCESS';
export const TRAINER_FIND_FAILURE = 'TRAINER_FIND_FAILURE';

export const TRAINER_DELETE_REQUEST = 'TRAINER_DELETE_REQUEST';
export const TRAINER_DELETE_SUCCESS = 'TRAINER_DELETE_SUCCESS';
export const TRAINER_DELETE_FAILURE = 'TRAINER_DELETE_FAILURE';

export const TRAINER_ACTION_REQUEST = 'TRAINER_ACTION_REQUEST';
export const TRAINER_ACTION_SUCCESS = 'TRAINER_ACTION_SUCCESS';
export const TRAINER_ACTION_FAILURE = 'TRAINER_ACTION_FAILURE';



