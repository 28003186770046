import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup,
  Input,
  Spinner,
  UncontrolledDropdown
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "react-data-table-component"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { lessonGet, lessonDelete, lessonPublish, lessonSubmit } from "../../store/lessons/lessonActions"
import { Link } from "react-router-dom"
import { LessonStatus } from "../../components/app/statusBudges"
import { BASE_URL } from "../../common/config"
import { LessonListComponent } from "./components/lessonList"
import LessonFormCanvas from "./form/lessonFormCanvas"
import { courseFind } from "../../store/courses/courseActions"

const Lessons = () => {
  document.title = "Lessons"
  const dispatch = useDispatch()
  const { Lessons } = useSelector(state => state)
  const [search, setSearch] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const { lessons, loading, error } = Lessons
  const [form, setForm] = useState(false);


  useEffect(() => {
    setFilteredData(lessons.filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase())))
  }, [lessons, search])

  useEffect(() => dispatch(lessonGet()), [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Courses" breadcrumbItem="Lessons" />

          <Card>
            <CardBody>
             <div className="">
               <FormGroup className="input-group float-end">
                 <Input placeholder="search" value={search} onChange={e => setSearch(e.target.value)} />
                 <Button className="" size="sm" outline color={"primary"} onClick={() => dispatch(lessonGet())}>
                   {loading && <Spinner size="sm" />}
                   {!loading && <i className="bx bx-repeat"></i> }
                   Reload
                 </Button>
                 <Button className="" size="sm" outline color={"primary"} onClick={() => setForm(!form)}>
                   <i className="bx bx-plus"></i> Lesson
                 </Button>

               </FormGroup>

             </div>
            </CardBody>
            <LessonListComponent
              hideHeaders
              lessons={filteredData}
              loading={filteredData.length == 0 && loading}
            />


          </Card>


        </Container>
      </div>

      {form && <LessonFormCanvas lessonId={0} isOpen={form} callBack={(fields) => {
        setForm(false)
        dispatch(lessonSubmit(fields))
        // dispatch(courseFind(id))
      }} toggle={() => setForm(!form)} courseId={0} />}
    </React.Fragment>
  )
}

export default Lessons
