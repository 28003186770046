import {
  LESSON_DELETE_FAILURE,
  LESSON_DELETE_REQUEST, LESSON_DELETE_SUCCESS,
  LESSON_DEPRECATE_FAILURE,
  LESSON_DEPRECATE_REQUEST, LESSON_DEPRECATE_SUCCESS, LESSON_FIND_FAILURE, LESSON_FIND_REQUEST, LESSON_FIND_SUCCESS,
  LESSON_GET_FAILURE,
  LESSON_GET_REQUEST,
  LESSON_GET_SUCCESS, LESSON_PUBLISH_FAILURE,
  LESSON_PUBLISH_REQUEST,
  LESSON_PUBLISH_SUCCESS, LESSON_SUBMIT_FAILURE, LESSON_SUBMIT_REQUEST, LESSON_SUBMIT_SUCCESS
} from "./lesssonTypes"

const INIT_STATE = {
  lesson: false,
  loading: false,
  error: false,
  lessons: [],
}


const Lessons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LESSON_GET_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case LESSON_GET_SUCCESS : {
      return { ...state, loading: false, error: false, lessons: action.payload }
    }
    case LESSON_GET_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }

    case LESSON_FIND_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case LESSON_FIND_SUCCESS : {
      return { ...state, loading: false, error: false, lesson: action.payload }
    }
    case LESSON_FIND_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }

    case LESSON_PUBLISH_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case LESSON_PUBLISH_SUCCESS : {
      return { ...state, loading: false, error: false }
    }
    case LESSON_PUBLISH_FAILURE : {
      return { ...state, loading: true, error: action.payload }
    }

    case LESSON_SUBMIT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case LESSON_SUBMIT_SUCCESS : {
      return { ...state, loading: false, error: false }
    }
    case LESSON_SUBMIT_FAILURE : {
      return { ...state, loading: true, error: action.payload }
    }

    case LESSON_DEPRECATE_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case LESSON_DEPRECATE_SUCCESS : {
      return { ...state, loading: false, error: false }
    }
    case LESSON_DEPRECATE_FAILURE : {
      return { ...state, loading: true, error: action.payload }
    }

    case LESSON_DELETE_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case LESSON_DELETE_SUCCESS : {
      return { ...state, loading: false, error: false }
    }
    case LESSON_DELETE_FAILURE : {
      return { ...state, loading: true, error: action.payload }
    }


    default :
      return { ...state }
  }
}

export default Lessons




