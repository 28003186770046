import React from "react"
import axios from "axios"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import UploadComponent from "../../../components/app/upload"
import { UploadVideo } from "../../../components/app/uploadVideo"


export default class CourseWizardTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: false,
      fields: false,
      loading: false
    }
  }

  set = () => {
    axios.get("app/course/form/0")
      .then(({ data }) => this.setState({ form: data, fields: data.fields }))
    // .catch()
  }
  componentDidMount = () => this.set()

  onChange = (field, value) => {
    let { fields } = this.state
    fields[field] = value
    this.setState({ fields }, () => this.props.updateCourse(this.state.fields))
  }

  render() {

    const { onSubmit } = this.props
    const { form, fields } = this.state
    return <>
      {form && <Row>
        <Col sm={7}>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit(fields)
            }}>
            <FormGroup className="form-floating mb-3">

              <Input
                type="text"
                className="form-control"
                id="basicpill-firstname-input12"
                placeholder="Course Title"
                onChange={(val) => this.onChange("title", val.target.value)} />
              <Label htmlFor="basicpill-firstname-input12">
                Course Title
              </Label>
            </FormGroup>
            <div className="form-floating mb-3">
              <select
                defaultValue={form.fields.value}
                onChange={(val) => this.onChange("category", val.target.value)}
                className="form-select">
                <option>Select</option>
                {form.categories.map(row => <option
                  selected={row.id === form.fields.category}
                  key={`category-${row.id}`}
                  value={row.id}>{row.title}</option>)}
              </select>
              <label htmlFor="floatingSelectGrid">Category</label>
            </div>
            <FormGroup>
              <Label htmlFor="input-description">Description</Label>
              <textarea
                htmlFor="input-description"
                className="form-control"
                onChange={(value) => this.onChange("description", value.target.value)}
                rows={5}
              />
            </FormGroup>
          </Form>
        </Col>
        <Col sm={5}>
          <UploadVideo  video={fields.video} updateVideo={data => this.onChange('video', data)} />


          {/*<pre>{JSON.stringify(fields, null, 2)}</pre>*/}
        </Col>
      </Row>}
    </>
  }


}
