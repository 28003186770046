import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Row, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { videoGet } from "../../store/video/videoActions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { UploadVideo } from "../../components/app/uploadVideo"


export const Videos = () => {
  const dispatch = useDispatch()
  const { Videos } = useSelector(state => state)
  const { videos, loading } = Videos
  const [video, setVideo] = useState()
  const [search, setSearch] = useState("")

  const get = () => dispatch(videoGet())

  useEffect(get, [])
  useEffect(() => {
    video !== false && get()
  }, [video])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={"Videos"}
          breadcrumbItem={"Courses"}
        />

        <Card className="bg-transparent shadow-none">
          <CardBody className="bg-white mb-3">
            <FormGroup className="input-group inline float-end">
              <Input placeholder="Search Videos..." value={search} onChange={e => setSearch(e.target.value)} />
              <Button className="" size="sm" outline color={"primary"} onClick={get}>
                {loading && <Spinner size="sm" />}
                {!loading && <i className="mbi mdi mdi-reload"></i>}
                {" "} Reload
              </Button>
            </FormGroup>
          </CardBody>

          <Row>
            <Col sm={4} md={3}>
              <UploadVideo height={250} label={"UPLOAD VIDEO"} video={video} title="Upload Course Trainer"
                           updateVideo={data => setVideo(data)} />
            </Col>
            {videos.filter((row) => JSON.stringify(row).toLowerCase().includes(search.toLowerCase())).map(video => <Col
              sm={4} md={3} key={`video-${video.id}`}>
              <Link to={`/course/video/${video.id}`}>
                <Card className="rounded-0 overflow-hidden"
                      style={{
                        height: 250,
                        backgroundImage: `url("${video.thumbnail}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        paddingTop: 180
                      }}>

                  <CardBody className="bg-white ">
                    <CardTitle className="text-dark font-size-13">
                      {video.title ? video.title.substr(0, 50) : "Untitled"}
                      {video.title && video.title.length > 50 && `...${video.title.substr(video.title.length - 4, video.title.length)}`}
                    </CardTitle>
                  </CardBody>

                </Card>
              </Link>
            </Col>)}
          </Row>

        </Card>

      </Container>
    </div>
  </React.Fragment>


}
