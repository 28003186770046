import {
  LESSON_GET_REQUEST,
  LESSON_GET_SUCCESS,
  LESSON_GET_FAILURE,
  LESSON_PUBLISH_REQUEST,
  LESSON_PUBLISH_SUCCESS,
  LESSON_PUBLISH_FAILURE,
  LESSON_DEPRECATE_REQUEST,
  LESSON_DEPRECATE_SUCCESS,
  LESSON_DEPRECATE_FAILURE,
  LESSON_DELETE_REQUEST,
  LESSON_DELETE_SUCCESS,
  LESSON_DELETE_FAILURE,
  LESSON_FIND_REQUEST,
  LESSON_FIND_SUCCESS,
  LESSON_FIND_FAILURE,
  LESSON_SUBMIT_REQUEST,
  LESSON_SUBMIT_SUCCESS, LESSON_SUBMIT_FAILURE
} from "./lesssonTypes"
import axios from "axios"
import { COURSE_SUBMIT_FAILURE, COURSE_SUBMIT_REQUEST, COURSE_SUBMIT_SUCCESS } from "../courses/courseTypes"


export const lessonGet = () => dispatch => {
  dispatch({ type: LESSON_GET_REQUEST })
  axios
    .get(`app/lesson/get`)
    .then(({ data }) => dispatch({ type: LESSON_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: LESSON_GET_FAILURE, payload: message }))
}

export const lessonFind = (id) => dispatch => {
  dispatch({ type: LESSON_FIND_REQUEST })
  axios
    .get(`app/lesson/get/${id}`)
    .then(({ data }) => dispatch({ type: LESSON_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: LESSON_FIND_FAILURE, payload: message }))
}

export const lessonSubmit = (data) => dispatch => {
  dispatch({ type: LESSON_SUBMIT_REQUEST })
  axios.post("/app/lesson/submit", data)
    .then(res => dispatch({ type: LESSON_SUBMIT_SUCCESS, payload: res.data }))
    .then(res => dispatch({ type: LESSON_SUBMIT_FAILURE, payload: res.message }))
}

export const lessonPublish = (id, callBack) => dispatch => {
  dispatch({ type: LESSON_PUBLISH_REQUEST })
  axios
    .post(`app/lesson/publish`, { id })
    .then(({ data }) => dispatch({ type: LESSON_PUBLISH_SUCCESS }))
    .catch(({ message }) => dispatch({ type: LESSON_PUBLISH_FAILURE, payload: message }))
    .finally(() => callBack ? callBack() : dispatch(lessonGet()))
}


export const lessonDeprecate = (id, callBack) => dispatch => {
  dispatch({ type: LESSON_DEPRECATE_REQUEST })
  axios
    .post(`app/lesson/deprecate`, { id })
    .then(({ data }) => dispatch({ type: LESSON_DEPRECATE_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: LESSON_DEPRECATE_FAILURE, payload: message }))
    .finally(() => callBack ? callBack() : dispatch(lessonGet()))

}


export const lessonDelete = (id, callBack) => dispatch => {
  dispatch({ type: LESSON_DELETE_REQUEST })
  axios
    .post(`app/lesson/delete`, { id })
    .then(({ data }) => dispatch({ type: LESSON_DELETE_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: LESSON_DELETE_FAILURE, payload: message }))
    .finally(() => callBack ? callBack() : dispatch(lessonGet()))

}




