import React, { useEffect, useState } from "react"
import {
  Button, ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container, DropdownItem,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent, Table,
  TabPane
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { courseFind, courseGet, coursePublish, courseSubmit } from "../../store/courses/courseActions"
import { CourseStatus } from "../../components/app/statusBudges"
import { LessonListComponent } from "../lessons/components/lessonList"
import CourseFormCanvas from "./form/courseFormCanvas"
import LessonFormCanvas from "../lessons/form/lessonFormCanvas"
import { lessonSubmit } from "../../store/lessons/lessonActions"

const CourseDetails = () => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(false)
  const [lessonForm, setLessonForm] = useState(false)
  const { Courses } = useSelector(state => state)
  const { course, loading } = Courses
  const { id } = useParams()
  const [active, setActive] = useState("lessons")

  // document.title = `Courses | ${course && course.info.title}`

  useEffect(() => {
    dispatch(courseFind(id))
  }, [])


  return course && <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Courses"
                     breadcrumbItem={`${course ? course.info.title : (loading ? "Loading" : "Not found")}`} />
        <Row>
          <Col sm={4}>
            <Card>
              {course && course.video && <img src={course.video.thumbnail} />}
              <CardBody>
                <div className="justify-content-center text-center mb-5 align-content-center align-items-center">
                  <ButtonGroup className="align-self-center">
                    <Button outline color="primary" onClick={() => {
                      setForm(true)

                    }}><i className="bx bx-edit"></i> Edit</Button>
                    {course.info.status !== 'published' &&  <Button outline color="success"
                             onClick={() => dispatch(coursePublish(course.info.id))}
                    > <i className="bx bx-badge-check" />{" "}
                      Publish</Button>}

                    <Button outline color="danger" onClick={() => {
                      // setForm(true)

                    }}><i className="bx bx-trash"></i> Delete</Button>


                  </ButtonGroup>
                </div>
                <Table>
                  <tbody>
                  <tr>
                    <th>TITLE</th>
                    <td>{course && course.info.title}</td>
                  </tr>
                  <tr>
                    <th>CATEGORY</th>
                    <td>{course && course.category && course.category.title}</td>
                  </tr>
                  <tr>
                    <th>TRAINER</th>
                    <td>{course && course.trainer && course.trainer.name}</td>
                  </tr>
                  <tr>
                    <th>STATUS</th>
                    <td><CourseStatus status={course.info.status} /></td>
                  </tr>
                  <tr>
                    <th>CREATED</th>
                    <td>{course && moment(course.info.created_at).fromNow()}</td>
                  </tr>
                  <tr>
                    <th>LESSONS</th>
                    <td>{course && course.lessons.length}</td>
                  </tr>
                  <tr>
                    <th>VIEWS</th>
                    <td>{course && course.views}</td>
                  </tr>
                  <tr>
                    <th>LIKES</th>
                    <td>{course && course.likes}</td>
                  </tr>
                  <tr>
                    <th>SUBSCRIBERS</th>
                    <td>{course && course.notifies}</td>
                  </tr>
                  <tr>
                    <th>COMMENTS</th>
                    <td>{course && course.comments.length}</td>
                  </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col sm={8}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Course Details</CardTitle>
              </CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    active={active === "lessons"}
                    onClick={() => setActive("lessons")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                    <span className="d-none d-sm-block">Lessons</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    active={active === "information"}
                    onClick={() => setActive("information")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                    <span className="d-none d-sm-block">Information</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    active={active === "comments"}
                    onClick={() => setActive("comments")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                    <span className="d-none d-sm-block">Comments</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={active}
                className="text-muted">
                <TabPane tabId="lessons">
                  {course && <LessonListComponent
                    callBack={() => dispatch(courseFind(id))}
                    hideCategory={true}
                    hideCourse={true}
                    lessons={course.lessons}
                    loading={loading} />}

                  <div className="text-center mb-5">

                    <Button className="align-self-center" outline color="primary" onClick={() => setLessonForm(true)}>Create
                      Lesson</Button>
                  </div>

                </TabPane>
                <TabPane tabId="information">
                  <div className="p-2">
                    <h4>Description</h4>
                    {course.info.description}
                    <hr />
                    <h4>Outline</h4>
                    {course.info.outline}
                    <hr />

                    <h4>Requirements</h4>
                    {course.info.requirements}
                    <hr />
                    <hr />

                  </div>
                </TabPane>
                <TabPane tabId="comments">

                  <div className="m-5">
                    <div>
                      {course.comments.map(row => <div
                        key={`comments-${row.id}`}
                        className="d-flex py-3">
                        <div className="avatar-xs me-3">
                          <div className="avatar-title rounded-circle bg-light text-primary">
                            <i className="bx bxs-user"></i>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 mb-1">
                            Delores Williams{" "}
                            <small className="text-muted float-end">
                              {moment(row.created_at).fromNow()}
                            </small>
                          </h5>
                          <p className="text-muted">
                            {row.message}
                          </p>
                          <div>
                            <Link to="#" className="text-success">
                              <i className="mdi mdi-reply"></i> Reply
                            </Link>
                          </div>
                        </div>
                      </div>)}

                    </div>
                  </div>

                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>


    {form && <CourseFormCanvas courseId={id} isOpen={form} callBack={(fields) => {
      setForm(false)
      dispatch(courseSubmit(fields))
      dispatch(courseFind(id))
    }} toggle={() => setForm(!form)} categoryId={0} />}
    <LessonFormCanvas lessonId={0} isOpen={lessonForm} callBack={(fields) => {
      setLessonForm(false)
      dispatch(lessonSubmit(fields))
      dispatch(courseFind(id))
    }} toggle={() => setLessonForm(!lessonForm)} courseId={id} />
  </React.Fragment>

}

export default CourseDetails
