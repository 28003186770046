import React from "react"
import {
  Button, ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"


import { CourseApprovalStatus } from "../../../components/app/statusBudges"
import images from "../../../assets/images"
import { adminCourseApprove, adminCourseReject } from "../../../store/admin/courses/adminCourseActions"
import { UserChip } from "../../../components/app/userComponents"
import { Image } from "../../../components/app/image"


export const CourseList = ({ loading, courses, onClick, omits = [] }) => {


  const dispatch = useDispatch()
  return <>
    <div className="table-responsive">
      <Table className="project-list-table table-nowrap align-middle table-borderless">
        <thead>
        <tr>
          <th scope="col" style={{ width: "60px" }}>#</th>
          <th scope="col">Course Title</th>
          {omits.indexOf("traffic") < 0 && <>
            <th  className="text-center" style={{width:'80px'}} scope="col">Comments</th>
            <th  className="text-center" style={{width:'80px'}} scope="col">Reminders</th>
            <th  className="text-center" style={{width:'80px'}} scope="col">Views</th>
          </>}
          {omits.indexOf("approval") < 0 && <th className="align-content-end justify-content-end align-items-end rightMost right" scope="col">Approval</th>}
          {omits.indexOf("actions") < 0 && <th className="text-end" scope="col">Action</th>}
        </tr>
        </thead>
        <tbody>
        {courses && map(courses, (course, index) => (
          <tr key={index}>
            <td>
              <Image

                src={course.thumbnail}
                alt=""
                className="avatar-sm rounded-4 shadow-sm"
              />
            </td>
            <td>
              <h5 className="text-truncate font-size-14">
                <Link
                  onClick={() => onClick(course.id)}
                  // to={`/admin/courses/${course.id}`}
                  className="text-dark"
                >
                  {course.title}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <strong>{course.category_title && ` ${course.category_title}`}</strong>
                <UserChip id={course.trainer} plain avatar={course.trainer_avatar} name={course.trainer_name} />
              </p>
            </td>


            {omits.indexOf("traffic") < 0 && <>
              <td className="text-center">{course.comments}</td>
              <td className="text-center">{course.bells}</td>
              <td className="text-center">{course.views}</td>
            </>}
            {omits.indexOf("approval") < 0 && <td className="align-content-end justify-content-end align-items-end rightMost right"><CourseApprovalStatus approval={course.approval} /></td>}
            {omits.indexOf("actions") < 0 &&
            <td className="align-content-end justify-content-end align-items-end rightMost right">
              {course.approval === "pending" && <ButtonGroup className="align-items-end float-end">
                <Button size="sm" color="danger"
                        onClick={() => dispatch(adminCourseReject(course.id))}
                        outline>
                  <i className="mdi mdi-book-edit text-danger " />{" "}

                  Reject</Button>
                <Button size="sm" color="success"
                        onClick={() => dispatch(adminCourseApprove(course.id))}
                        outline>
                  <i className="bx bx-badge-check text-success " />{" "}

                  Approve</Button>

              </ButtonGroup>
              }

              {false && course.approval === "pending" && <UncontrolledDropdown>
                <DropdownToggle
                  className="card-drop"
                  tag="a"
                >
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {course.approval === "pending" && <DropdownItem
                    onClick={() => dispatch(adminCourseApprove(course.id))}
                  >
                    <i className="bx bx-badge-check font-size-16 text-success me-1" />{" "}
                    Approve
                  </DropdownItem>}
                  {course.approval === "pending" && <DropdownItem
                    href="#"
                    onClick={() => dispatch(adminCourseReject(course.id))}
                  >
                    <i className="mdi mdi-book-edit font-size-16 text-danger me-1" />{" "}
                    Reject
                  </DropdownItem>}

                </DropdownMenu>
              </UncontrolledDropdown>}
            </td>}
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
    <Row>
      <Col xs="12">
        <div className="text-center my-3">
          {loading && <Link to="#" className="text-success">
            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
            Load more
          </Link>}
        </div>
      </Col>
    </Row>

  </>
}
