import {
  COURSE_DELETE_FAILURE,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS, COURSE_FIND_FAILURE, COURSE_FIND_REQUEST, COURSE_FIND_SUCCESS,
  COURSE_GET_FAILURE,
  COURSE_GET_REQUEST,
  COURSE_GET_SUCCESS, COURSE_PUBLISH_FAILURE, COURSE_PUBLISH_REQUEST, COURSE_PUBLISH_SUCCESS
} from "./courseTypes"

const initState = {
  loading: false,
  courses: [],
  details: false,
  course: false,
  error: false
}


const Courses = (state = initState, action) => {
  switch (action.type){
    case COURSE_GET_REQUEST: {return {...state, loading: true, error: false}}
    case COURSE_GET_SUCCESS: {return {...state, loading: false, error: false, courses: action.payload}}
    case COURSE_GET_FAILURE: {return {...state, loading: false, error: action.payload}}

    case COURSE_FIND_REQUEST: {return {...state, loading: true, error: false}}
    case COURSE_FIND_SUCCESS: {return {...state, loading: false, error: false, course: action.payload}}
    case COURSE_FIND_FAILURE: {return {...state, loading: false, error: action.payload}}

    case COURSE_PUBLISH_REQUEST: {return {...state, loading: true, error: false}}
    case COURSE_PUBLISH_SUCCESS: {return {...state, loading: false, error: false}}
    case COURSE_PUBLISH_FAILURE: {return {...state, loading: false, error: action.payload}}

    case COURSE_DELETE_REQUEST: {return {...state, loading: true, error: false}}
    case COURSE_DELETE_SUCCESS: {return {...state, loading: false, error: false}}
    case COURSE_DELETE_FAILURE: {return {...state, loading: false, error: action.payload}}



    default: {return {...state}}
  }
}


export default Courses;
