
import {
  TRAINER_GET_REQUEST,
  TRAINER_GET_SUCCESS,
  TRAINER_GET_FAILURE,
  TRAINER_FIND_REQUEST,
  TRAINER_FIND_SUCCESS,
  TRAINER_FIND_FAILURE,
  TRAINER_DELETE_REQUEST,
  TRAINER_DELETE_SUCCESS,
  TRAINER_DELETE_FAILURE,
  TRAINER_ACTION_REQUEST,
  TRAINER_ACTION_SUCCESS,
  TRAINER_ACTION_FAILURE
}from './trainerTypes'

const INIT = {
  trainers: [],
  trainer: false,
  loading: false,
  error: ""
}




const Trainers = (state = INIT, action)=>{

  switch(action.type){
    case TRAINER_GET_REQUEST : return {...state, loading: true, error: ''}
    case TRAINER_GET_SUCCESS : return {...state, loading: false, error: '', trainers: action.payload}
    case TRAINER_GET_FAILURE : return {...state, loading: false, error: action.payload}
    case TRAINER_FIND_REQUEST : return {...state, loading: true, error: ''}
    case TRAINER_FIND_SUCCESS : return {...state, loading: false, error: '', trainer: action.payload}
    case TRAINER_FIND_FAILURE : return {...state, loading: false, error: action.payload}
    case TRAINER_ACTION_REQUEST : return {...state, loading: true, error: ''}
    case TRAINER_ACTION_SUCCESS : return {...state, loading: false, error: '', }
    case TRAINER_ACTION_FAILURE : return {...state, loading: false, error: action.payload}

    default : return {...state}
  }

}

export default Trainers;
