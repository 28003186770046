import React, { useEffect, useState } from "react"
import {
  Badge,
  Button, Card, CardBody, CardTitle,
  Col,
  Container, FormGroup, Input, Nav, NavItem, NavLink,
  Row, Spinner
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { adminCourseGet } from "../../store/actions"
import { CourseList } from "./components/courseList"
import classnames from "classnames"
import { CourseDetailsModal } from "./courseDetailsModal"

const Courses = () => {


  //meta title
  document.title = "Courses"
  const [active, setActive] = useState("pending")
  const [omits, setOmits] = useState([])
  const [course, setCourse] = useState(false)
  const [modal, setModal] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const { AdminCourses } = useSelector(state => state)

  const { courses, loading } = AdminCourses

  const get = () => dispatch(adminCourseGet())
  useEffect(get, [])
  useEffect(() => setModal(course !== false), [course])
  useEffect(() => {
    if (active === "pending") setOmits(["subscriptions", "traffic"])
    if (active === "rejected") setOmits(["subscriptions", "traffic", "actions"])
    if (active === "approved") setOmits(["actions"])

  }, [active])

  const colors = [
    { approval: "pending", color: "warning", icon: "bx bx-hourglass" },
    { approval: "rejected", color: "danger", icon: "bx bx-block" },
    { approval: "approved", color: "success", icon: "bx bx-check-circle" }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Courses" breadcrumbItem="All Courses" />
          <Row>
            <Col lg="12">
              <div>
                <Card className="bg-transparent">
                  <CardBody className="bg-white">
                    <FormGroup className="input-group inline float-end">
                      <Input placeholder="Search Courses..." value={search} onChange={e => setSearch(e.target.value)} />
                      <Button className="" size="sm" outline color={"primary"} onClick={get}>
                        {loading && <Spinner size="sm" />}
                        {!loading && <i className="mbi mdi mdi-reload"></i>}
                        {" "} Reload
                      </Button>
                    </FormGroup>

                  </CardBody>

                  <Nav tabs className="nav-tabs-custom nav-justified">

                    {colors.map((row, index) => <NavItem className="rounded-0" key={`approval-${index}`}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${classnames({ active: active === row.approval })} rounded-0`}
                        onClick={() => setActive(row.approval)}
                      >
                        <i className={`${row.icon} text-${row.color} font-size-20`}></i>
                        <span className="d-none d-sm-block ">
                          {row.approval.toUpperCase()}{" "}
                          <Badge className="me-1" color={row.color}> {" "}
                            {courses.filter(course => course.approval === row.approval).filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase())).length}</Badge>
                          </span>
                      </NavLink>
                    </NavItem>)}
                  </Nav>
                  <CourseList
                    onClick={(courseID) => setCourse(courseID)}
                    omits={omits}
                    loading={loading}
                    courses={courses.filter(row => row.approval === active).filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()))}
                  />
                </Card>


              </div>
            </Col>
          </Row>


        </Container>
        <CourseDetailsModal toggle={() => setCourse(false)} isOpen={modal} courseID={course} />
      </div>


    </React.Fragment>
  )
}

export default Courses
