import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Container, FormGroup, Input, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { adminMPesaGet } from "../../store/admin/Mpesa/adminMpesaActions"
import moment from "moment"
import { Link } from "react-router-dom"
import { MPesaDetailModal } from "./mpesaDetailModal"

const MPesa = () => {
  const dispatch = useDispatch()
  const { MPesa } = useSelector(state => state)
  const { records, record, loading } = MPesa
  const get = () => dispatch(adminMPesaGet())
  useEffect(get, [])


  const [modal, setModal] = useState(false)
  const [id, setID] = useState(false)
  const [search, setSearch] = useState("")


  useEffect(() => {
    setModal(id !== false)
  }, [id])

  document.title = "App Skill | Admin | Finance | Mpesa"
  const columns = [
    // {name: "id", selector: ({id}) => id, sortable: true},
    {
      name: "NAMES", style: { fontFamily: "Consolas" },
      selector: ({ FirstName }) => FirstName,
      width: "220px",
      sortable: true,

      cell: ({ id, FirstName, MiddleName, LastName }) => <Link onClick={()  => {
        setID(id)
        console.log(id)
      }}>
        {`${FirstName} ${MiddleName} ${LastName}`}
      </Link>
    },
    {
      name: "TYPE",
      style: { fontFamily: "Consolas" },
      selector: ({ TransactionType }) => TransactionType,
      sortable: true,
      omit: true

    },
    {
      name: "MPESA REF",
      style: { fontFamily: "Consolas" },
      wrap: true,
      selector: ({ TransID }) => TransID,
      sortable: true
    },
    {
      name: "PAYBILL",
      style: { fontFamily: "Consolas" },
      selector: ({ BusinessShortCode }) => BusinessShortCode,
      sortable: true,
      omit: true
    },
    {
      name: "ACCOUNT",
      style: { fontFamily: "Consolas" },
      selector: ({ BillRefNumber }) => BillRefNumber,
      sortable: true
    },
    { name: "PHONE", style: { fontFamily: "Consolas" }, selector: ({ MSISDN }) => MSISDN, sortable: true },
    { name: "Amount", style: { fontFamily: "Consolas" }, selector: ({ TransAmount }) => TransAmount, sortable: true },
    {
      name: "Transaction Date",
      right: true,
      width: "220px",
      style: { fontFamily: "Consolas" },
      selector: ({ created_at }) => moment(created_at).format("Do, MMM YYYY HH:mm:ss"),
      sortable: true
    }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Finance" breadcrumbItem="MPesa" />
          <Card>
            <CardBody>
              <FormGroup className="input-group inline float-end">
                <Input placeholder="search" value={search} onChange={e => setSearch(e.target.value)} />
                <Button className="" size="sm" outline color={"primary"} onClick={get}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <i className="mbi mdi mdi-reload"></i>}
                  {" "} Reload
                </Button>
              </FormGroup>
            </CardBody>
            <Datatable
              columns={columns}
              data={records.filter(row => (JSON.stringify(row)).toLowerCase().includes(search.toLowerCase()))}
              pagination
              highlightOnHover
              striped
              progressPending={loading && records.length === 0} />
          </Card>


        </Container>
      </div>
      <MPesaDetailModal isOpen={modal} toggle={() => {
        // setModal(false)
        setID(false)
      }} id={id} />

    </React.Fragment>
  )
}

export default MPesa
