import {
  Button, Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CourseStatus } from "../../components/app/statusBudges"
import moment from "moment"
import { LessonListComponent } from "../../trainer/lessons/components/lessonList"
import { Link } from "react-router-dom"
import { UserChip } from "../../components/app/userComponents"
import { VideoPlayer } from "../../trainer/video/playVideo"
import { adminLessonFind } from "../../store/admin/lessons/adminLessonActions"


export const LessonDetailModal = ({ lessonID, isOpen, toggle }) => {
  const dispatch = useDispatch()
  const { AdminLessons } = useSelector(state => state)
  const get = () => dispatch(adminLessonFind(lessonID))
  const [active, setActive] = useState("information")
  useEffect(() => {
    if (isOpen && lessonID) get()
  }, [isOpen, lessonID])

  const { lesson, loading } = AdminLessons


  return <Modal
    size="lg"
    isOpen={isOpen}
    toggle={toggle}
  >
    <div className="modal-header">
      <h5
        className="modal-title mt-0"
        id="exampleModalFullscreenLabel"
      >
        {loading ? "Loading"  : lesson && lesson.info && lesson.info.title}
      </h5>
      <button
        onClick={toggle}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">

      {lesson && <>
        <Card>

          <Nav tabs className="nav-tabs-custom nav-justified">

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                active={active === "information"}
                onClick={() => setActive("information")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                <span className="d-none d-sm-block">Information</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                active={active === "comments"}
                onClick={() => setActive("comments")}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                <span className="d-none d-sm-block">Comments</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={active}
            className="text-muted">

            <TabPane tabId="information">
              <div className="p-2">
                <Row>
                  <Col sm={6}>
                    <Table>
                      <tbody>
                      <tr>
                        <th>TITLE</th>
                        <td>{lesson && lesson.info.title}</td>
                      </tr>
                      <tr>
                        <th>COURSE</th>
                        <td>{lesson && lesson.course.title}</td>
                      </tr>
                      <tr>
                        <th>CATEGORY</th>
                        <td>{lesson && lesson.category.title}</td>
                      </tr>
                      <tr>
                        <th>TRAINER</th>
                        <td>{lesson && lesson.trainer && lesson.trainer.name}</td>
                      </tr>
                      <tr>
                        <th>STATUS</th>
                        <td><CourseStatus status={lesson.info.status} /></td>
                      </tr>
                      <tr>
                        <th>CREATED</th>
                        <td>{lesson && moment(lesson.info.created_at).fromNow()}</td>
                      </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={6}>
                    {lesson && lesson.video && <VideoPlayer video={lesson.video.video} />}
                  </Col>
                </Row>
                <h4>Description</h4>
                {lesson.info.description}
                <hr />
                <h4>Outline</h4>
                {lesson.info.outline}
                <hr />
                <hr />

              </div>
            </TabPane>
            <TabPane tabId="comments">

              <div className="m-5">
                <div>
                  {lesson.comments.map(row => <div
                    key={`comments-${row.id}`}
                    className="d-flex py-3">
                    <div className="avatar-xs me-3">
                      <div className="avatar-title rounded-circle bg-light text-primary">
                        <i className="bx bxs-user"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mb-1">
                        Delores Williams{" "}
                        <small className="text-muted float-end">
                          {moment(row.created_at).fromNow()}
                        </small>
                      </h5>
                      <p className="text-muted">
                        {row.message}
                      </p>
                      <div>
                        <Link to="#" className="text-success">
                          <i className="mdi mdi-reply"></i> Reply
                        </Link>
                      </div>
                    </div>
                  </div>)}

                </div>
              </div>

            </TabPane>
          </TabContent>
        </Card>
      </>}

    </div>

  </Modal>
}
