import React, { useState } from "react"
import DataTable from "react-data-table-component"
import { Link } from "react-router-dom"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useDispatch } from "react-redux"
import { LessonStatus } from "../../../components/app/statusBudges"
import { lessonDelete, lessonPublish, lessonSubmit } from "../../../store/lessons/lessonActions"
import LessonFormCanvas from "../form/lessonFormCanvas"


export const LessonListComponent = ({ lessons, loading, hideCategory, hideCourse, hideHeaders, callBack }) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(false)
  const [lesson, setLesson] = useState(0)

  const columns = [
    {
      name: "",
      selector: "id",
      width: "90px",
      cell: (row ) => <img
        width={70} height={70} src={row.video_thumbnail ? row.video_thumbnail : (row.video ? row.thumbnail : null)}
        className="rounded-1 shadow-sm m-2" />
    },
    {
      name: "Lesson Name",
      wrap: true,
      selector: "title",
      sortable: true,
      width: "50%",
      cell: (row) => <div>
        <div>
          <Link to={`/course/lesson/${row.id}`} className="text-dark font-size-17">{row.title}</Link>
        </div>
        <div className="m-0 p-0">
          {!hideCourse && <strong>
            <Link to={`/course/course/${row.course}`}>{row.course_title}</Link>
          </strong>}
          <span>
            {row.views && <span className="p-2"><i className="bx bx-group"></i> {row.views}</span>}
            {row.comments && <span className="p-2"><i className="bx bx-comment"></i> {row.comments}</span>}
            {row.likes && <span className="p-2"><i className="bx bx-like"></i> {row.likes ? row.likes : 0}</span>}
            {row.notifications &&
            <span className="p-2"><i className="bx bx-bell"></i> {row.notifications ? row.notifications : 0}</span>}
        </span>
        </div>
      </div>
    },
    { name: "Category", omit: hideCategory, selector: "category_title", sortable: true },
    {
      name: "Status",
      width: 100,
      right: true,
      selector: "status",
      sortable: true,
      cell: lesson => <LessonStatus status={lesson.status} />
    },
    {
      name: "",
      right: true,
      selector: "id",
      width: "60px",
      cell: row => {
        return row.id && <UncontrolledDropdown>
          <DropdownToggle

            className="card-drop"
            tag="a"
          >
            <i className="mdi mdi-dots-vertical font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {row.id !== "published" && <DropdownItem
              onClick={() => dispatch(lessonPublish(row.id, callBack))}
            >
              <i className="bx bx-badge-check font-size-16 text-success me-1" />{" "}
              Publish
            </DropdownItem>}
            <DropdownItem

              onClick={() => {
                setLesson(row.id)
                setForm(true)
              }}
            >
              <i className="mdi mdi-book-edit font-size-16 text-warning me-1" />{" "}
              Edit
            </DropdownItem>
            <DropdownItem

              onClick={() => dispatch(lessonDelete(row.id, callBack))}
            >
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      }
    }

  ]

  return <>{lessons && <DataTable
    noHeader={hideHeaders}
    noTableHead={hideHeaders}
    columns={columns}
    data={lessons}
    pagination
    progressPending={lessons.length == 0 && loading}
  />}

    {form && <LessonFormCanvas lessonId={lesson} isOpen={form} callBack={(fields) => {
      setForm(false)
      dispatch(lessonSubmit(fields))
      callBack && callBack()
      // dispatch(courseFind(id))
    }} toggle={() => setForm(!form)} courseId={0} />}
  </>


}
