import React, { useEffect } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Datatable from "react-data-table-component"
import { useDispatch, useSelector } from "react-redux"
import { trainerBlock, trainerGet, trainerReject, trainerVerify } from "../../store/admin/trainer/trainerActions"
import { userDelete } from "../../store/admin/users/userActions"
import { Link } from "react-router-dom"
import { TrainerStatus } from "../../components/app/statusBudges"
import { Image } from "../../components/app/image"

const Trainers = () => {
  const dispatch = useDispatch()
  const { Trainers } = useSelector(state => state)
  const { trainers, loading } = Trainers

  const get = () => dispatch(trainerGet())
  useEffect(() => {
    get()
  }, [])

  //meta title
  document.title = "App Skill | Admin | Trainers"

  const columns = [
    {
      selector: ({ id }) => id,
      name: "",
      width: "60px",
      wrap: true,
      right:true,
      cell: ({ avatar }) => <Image src={avatar} className="img-fluid rounded-circle shadow-sm" />
    },
    {
      selector: ({ name }) => name,
      name: "Name",
      width: "30%",
      sortable: true,
      cell: ({ id, name, email, trainer_status }) => <div>
        <div><strong>{name}</strong> </div>
        <div><TrainerStatus size={'sm'} status={trainer_status} /> <Link>{email}</Link></div>
      </div>
    },
    { selector: ({ courses }) => courses, center: true, name: "courses", sortable: true },
    { selector: ({ lessons }) => lessons, center: true, name: "Lessons", sortable: true },
    { selector: ({ views }) => views, center: true, name: "Views", sortable: true },
    { selector: ({ subscribers }) => subscribers, center: true, name: "Subscribers", sortable: true },
    { selector: ({ subscribers }) => subscribers, center: true, name: "Videos", sortable: true },
    { selector: ({ subscribers }) => subscribers, center: true, name: "Storage", sortable: true },
    // {
    //   selector: ({ roles }) => roles,
    //   name: "Roles",
    //   sortable: true,
    //   cell: ({ roles }, index) => roles.map(({ name, id }) =>
    //     <Badge color={name === "admin" ? "danger" : "info"}
    //            key={`role-${index}-${id}`}>{name.toUpperCase()}</Badge>)
    // },
    {
      selector: ({ id }) => id,
      name: "",
      width: "30px",
      right: true,
      sortable: false,
      cell: ({ id, trainer_status }) => {
        return <UncontrolledDropdown>
          <DropdownToggle
            className="card-drop"
            tag="a"
          >
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {trainer_status === "unverified" && <DropdownItem
              onClick={() => dispatch(trainerVerify(id))}
            >
              <i className="bx bx-badge-check font-size-16 text-success me-1" />{" "}
              Verify
            </DropdownItem>}
            {trainer_status === "unverified" && <DropdownItem
              onClick={() => dispatch(trainerReject(id))}
            >
              <i className="bx bx-block font-size-16 text-warning me-1" />{" "}
              Reject
            </DropdownItem>}
            {(trainer_status === "verified" || trainer_status === 'rejected') && <DropdownItem
              onClick={() => dispatch(trainerBlock(id))}
            >
              <i className="bx bx-user-x font-size-16 text-danger me-1" />{" "}
              Block
            </DropdownItem>}
            {(trainer_status === 'blocked' || trainer_status === 'rejected') && <DropdownItem
              href="#"
              onClick={() => dispatch(userDelete(id))}
            >
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              Delete Trainer
            </DropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>
      }
    }

  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Admin" breadcrumbItem="Trainers" />
          <Card>
            <CardBody>
              <Button className="float-end" outline color={"primary"}
                      onClick={get}>{loading ? "Loading" : "Reload"}</Button>
              <CardTitle>TRAINERS</CardTitle></CardBody>
            <Datatable data={trainers} columns={columns} />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Trainers
