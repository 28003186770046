import axios from "axios"
import {
  ADMIN_ROLES_ACTION_FAILURE,
  ADMIN_ROLES_ACTION_REQUEST, ADMIN_ROLES_ACTION_SUCCESS,
  ADMIN_ROLES_FIND_FAILURE,
  ADMIN_ROLES_FIND_REQUEST, ADMIN_ROLES_FIND_SUCCESS,
  ADMIN_ROLES_GET_FAILURE,
  ADMIN_ROLES_GET_REQUEST,
  ADMIN_ROLES_GET_SUCCESS
} from "./roleTypes"


export const roleGet = () => dispatch => {
  dispatch({ type: ADMIN_ROLES_GET_REQUEST })
  axios.get("/admin/roles/get")
    .then(({ data }) => dispatch({ type: ADMIN_ROLES_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_ROLES_GET_FAILURE, payload: message }))
}


export const roleFind = (id) => dispatch => {
  dispatch({ type: ADMIN_ROLES_FIND_REQUEST })
  axios.get(`/admin/roles/get/${id}`)
    .then(({ data }) => dispatch({ type: ADMIN_ROLES_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_ROLES_FIND_FAILURE, payload: message }))
}

export const roleRemoveUser = (userID, roleID) => dispatch => {
  dispatch({ type: ADMIN_ROLES_ACTION_REQUEST })
  axios.post(`/admin/roles/remove/user`, { roleID, userID })
    .then(({ data }) => dispatch({ type: ADMIN_ROLES_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ADMIN_ROLES_ACTION_FAILURE, payload: message }))
}

export const roleCreate = (name, callBack) => dispatch => {
  dispatch({ type: ADMIN_ROLES_ACTION_REQUEST })
  axios.post(`/admin/roles/submit`, { name })
    .then(({ data }) => {
      dispatch({ type: ADMIN_ROLES_ACTION_SUCCESS })
      callBack(data.id)
    })
    .catch(({ message }) => dispatch({ type: ADMIN_ROLES_ACTION_FAILURE, payload: message }))


}




