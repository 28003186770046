import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle, FormGroup, Input, Spinner,
  UncontrolledDropdown
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  userBlock,
  userDelete,
  userGet,
  userMakeAdmin,
  userMakeTrainer, userRolesSubmit,
  userUnblock
} from "../../store/admin/users/userActions"
import Datatable from "react-data-table-component"
import { courseDelete, coursePublish } from "../../store/courses/courseActions"
import { Image } from "../../components/app/image"
import { UserStatus } from "../../components/app/statusBudges"
import moment from "moment"
import UserRoleFormModal from "./userRoleFormModal"

const Users = () => {
  document.title = "App Skill | Admin | Users"

  const dispatch = useDispatch()
  const { Users } = useSelector(state => state)
  const { users, loading } = Users
  const get = () => dispatch(userGet())
  const [isOpen, setIsOpen] = useState(false)
  const [user, setUser] = useState(false)
  const [search, setSearch] = useState('')
  useEffect(get, [])

  useEffect(() => {
    setIsOpen(user !== false)
  }, [user])

  const columns = [
    {
      selector: ({ id }) => id,
      name: "",
      width: "70px",
      sortable: false,
      cell: ({ avatar }) => <Image src={avatar} className="img-fluid rounded-circle shadow-sm" />
    },
    {
      selector: ({ name }) => name, name: "Name", sortable: true, cell: ({ name, email }) => <div>
        <div><strong>{name}</strong></div>
        <div><span>{email}</span></div>
      </div>
    },
    {
      selector: ({ status }) => status,
      name: "Status",
      sortable: true,
      cell: ({ status }) => <UserStatus status={status} />
    },
    {
      selector: ({ roles }) => roles,
      name: "Roles",
      sortable: true,
      wrap: true,
      cell: ({ roles }, index) =>
        <div className="">
         {roles.map(({ name, id }) => <Badge
           key={`role-${index}-${id}`}
           color={name === "admin" ? "danger" : "info"}

           className="inline me-2"
         >{name.toUpperCase()}</Badge>)}
        </div>
    },
    {
      selector: ({ last_seen }) => last_seen,
      name: "Last Seen",
      sortable: true,
      width:"120px",
      cell: ({ last_seen }) => moment(last_seen).fromNow()
    },
    {
      selector: ({ id }) => id, name: "",      width:"50px",
      right: true, sortable: false, cell: (row) => {
        return <UncontrolledDropdown>
          <DropdownToggle
            className="card-drop"
            tag="a"
          >
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {row.status === "active" && <DropdownItem
              onClick={() => setUser(row)}
            >
              <span className="">
                  <i className="mdi mdi-account-key-outline font-size-16 text-warning me-1" />{" "}
                Assign Role(s)
              </span>
            </DropdownItem>}

            {row.status !== "blocked" && <DropdownItem
              onClick={() => dispatch(userBlock(row.id))}
            >
              <span className="">
                <i className="mdi mdi-account-cancel-outline font-size-16 text-danger me-1" />{" "}
                Block User
              </span>
            </DropdownItem>}
            {row.status === "blocked" && <DropdownItem
              onClick={() => dispatch(userUnblock(row.id))}
            >
              <span className="">
                <i className="mdi mdi-account-check-outline font-size-16 text-danger me-1" />{" "}
                Unblock User
              </span>
            </DropdownItem>}


            {row.status === "blocked" && <DropdownItem
              href="#"
              onClick={() => dispatch(userDelete(row.id))}
            >
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              Delete User
            </DropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>
      }
    }

  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="Admin" />
          <Card>
            <CardBody>
              <FormGroup className="input-group inline float-end">
                <Input placeholder="Search Users..." value={search} onChange={e => setSearch(e.target.value)} />
                <Button className="" size="sm" outline color={"primary"} onClick={get}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <i className="mbi mdi mdi-reload"></i>}
                  {" "} Reload
                </Button>
              </FormGroup>
            </CardBody>
            <Datatable data={users.filter(row => JSON.stringify(row).toLowerCase().includes(search))} pagination striped paginationPerPage={20} columns={columns} />
          </Card>


        </Container>
      </div>
      {isOpen && <UserRoleFormModal user={user} isOpen={isOpen} toggle={(data) => {
        data && dispatch(userRolesSubmit(data, user.id))
        setUser(false)
        setIsOpen(false)

      }} />}
    </React.Fragment>
  )
}

export default Users
